import ApiService from "@/state/api.service";

export const state = {
    productos: [],
    stockProducto: [],
    errorProducto: null,
};

export const getters = {
    productos: (state) => state.productos,
    stockProducto: (state) => state.stockProducto,
    errorProducto: (state) => state.errorProducto,
};

export const mutations = {
    setProductos(state, newValue) {
        state.productos = newValue;
    },
    setStockProducto(state, newValue) {
        state.stockProducto = newValue;
    },
    setError(state, newValue) {
        state.errorProducto = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerProductos({ commit }, producto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/producto`)
                .then((response) => {
                    commit("setProductos", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerStockProductos({ commit }, productoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/producto/stock`)
                .then((response) => {
                    commit("setStockProducto", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
