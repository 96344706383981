import ApiMsProjectsService from '@/services/ms_projects_service'

export const state = {
  loading: false,
  trackings: [],
  trackingSummary: null,
  professionals: [],
  departments: [],
  activities: [],
  revisions: []
}

export const getters = {
  loading: (state) => state.loading,
  trackings: (state) => state.trackings,
  professionals: (state) => state.professionals,
  trackingSummary: (state) => state.trackingSummary,
  departments: (state) => state.departments,
  activities: (state) => state.activities,
  revisions: (state) => state.revisions
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_TRACKINGS(state, payload) {
    state.trackings = payload.map((item) => {
      return {
        ...item,
        edit: false
      }
    })
  },
  SET_NEW_TRACKING(state, payload) {
    state.trackings.unshift(payload)
  },
  SET_TRACKING_SUMMARY(state, payload) {
    state.trackingSummary = payload
  },
  SET_TRACKING_DRAFT(state, payload) {
    state.trackings.unshift(payload)
  },
  SET_EDIT_TRACKING(state, { id, edit }) {
    state.trackings.forEach((item) => {
      if (item.id == id) {
        item.edit = edit
      }
    })
  },
  SPLICE_TRACKING_DRAFT(state, payload) {
    state.trackings = state.trackings.filter((item) => item.id !== payload)
  },
  SET_PROFESSIONALS(state, payload) {
    state.professionals = payload
  },
  SET_DEPARTMENTS(state, payload) {
    state.departments = payload
  },
  SET_ACTIVITIES(state, payload) {
    state.activities = payload
  },
  SET_REVISIONS(state, payload) {
    state.revisions = payload
  }
}

export const actions = {
  async getTrackings({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.get(`/tracking_filter`, payload)
      commit('SET_TRACKINGS', resp.data)
      return resp
    } catch (error) {
      return error
    }
  },
  async getTrackingSummary({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.get(`/tracking_summary`, payload)
      commit('SET_TRACKING_SUMMARY', resp.data)
      return resp
    } catch (error) {
      return error
    }
  },
  async downloadExcelTracking({commit}, payload){
    try {
      const resp = await ApiMsProjectsService.get(`/tracking_xlsx`, payload, 'blob')
      return resp
    } catch (error) {
      return error.response
    }
  },
  async getProfessionals({ commit }) {
    try {
      const resp = await ApiMsProjectsService.get(`/fluxsolar_users`)
      commit('SET_PROFESSIONALS', resp.data)
      return resp
    } catch (error) {
      return error
    }
  },
  async getDepartments({ commit }) {
    try {
      const resp = await ApiMsProjectsService.get(`/departments`)
      commit('SET_DEPARTMENTS', resp.data)
      return resp
    } catch (error) {
      return error
    }
  },
  async getActivities({ commit }, payload) {
    try {
      if (payload.department_id) {
        const resp = await ApiMsProjectsService.get(`/activities`, payload)
        commit('SET_ACTIVITIES', resp.data)
        return resp
      }
      commit('SET_ACTIVITIES', [])
    } catch (error) {
      return error
    }
  },
  async getRevisions({ commit }, payload) {
    try {
      if (payload.activities_id) {
        const resp = await ApiMsProjectsService.get(`/revision`, payload)
        commit('SET_REVISIONS', resp.data)
        return resp
      }
      commit('SET_REVISIONS', [])
    } catch (error) {
      return error
    }
  },
  cleanTrackings({ commit }) {
    commit('SET_TRACKINGS', [])
  },
  setTrackingDraft({ commit }, payload) {
    commit('SET_TRACKING_DRAFT', payload)
  },
  setEditTracking({ commit }, payload) {
    commit('SET_EDIT_TRACKING', payload)
  },
  spliceTrackingDraft({ commit }, payload) {
    commit('SPLICE_TRACKING_DRAFT', payload)
  },
  async createTracking({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.post(`/tracking`, payload)
      commit('SET_NEW_TRACKING', resp.data)
      return resp
    } catch (error) {
      return error
    }
  },
  async updateTracking({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.put(`/update_tracking`, payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async deleteTracking({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.delete(
        `/delete_tracking?tracking_id=${payload.tracking_id}&user=${payload.user}`
      )
      return resp
    } catch (error) {
      return error
    }
  }
}
