<template>
   <v-overlay 
            :value="value"
            z-index="999999"
            opacity="0.8"
            color="#ffffff"
            class="terry-loader"
        >
            <img src="https://fluxsolar.cl/wp-content/themes/fluxsolar/assets/images/logo.png">
            <div class="slider">
                <div class="line"></div>
                <div class="subline inc"></div>
            <div class="subline dec"></div>
        </div>
   </v-overlay>
</template>

<script>
export default {
    name: 'LoadingFlux',
    props: {
        value: Boolean,
    },
};
</script>

<style scoped>
.terry-loader .slider{
    position: absolute;
    width: 200px;
    height: 5px;
    overflow-x: hidden;
    top: 50%;
    margin-top: 40px;
    left: 50%;
    margin-left: -100px;
    border-radius: 5px;
}

.terry-loader .line{
    position:absolute;
    background:#ffead1;
    width:150%;
    height:5px;
    border-radius: 5px;
}

.terry-loader .subline{
    position:absolute;
    background:#ff9b00;
    height:5px; 
    border-radius: 5px;
}
.terry-loader .inc{
    animation: increase 1s infinite;
}
.terry-loader .dec{
    animation: decrease 1s 0.5s infinite;
}

@keyframes increase {
    from { left: -5%; width: 5%; }
    to { left: 130%; width: 100%;}
}
@keyframes decrease {
    from { left: -80%; width: 80%; }
    to { left: 110%; width: 10%;}
}


</style>
