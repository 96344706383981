import ApiMsProjectsService from '@/services/ms_projects_service'

export const state = {
  loading: false,
  users: []
}

export const getters = {
  loading: (state) => state.loading,
  users: (state) => state.users
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_USERS(state, payload) {
    state.users = payload
  }
}

export const actions = {
  async getUsersFromListEmails({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(`/list_users`, payload)).data
      commit('SET_USERS', resp)
      return resp
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async getUserFromEmail({ commit }, payload) {
    try {
      const resp = (
        await ApiMsProjectsService.get(`/get_user/${payload.email}?email=${payload.email}`)
      ).data
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllUsersActiveContract({ commit }, payload) {
    try {
      const resp = (await ApiMsProjectsService.get(`/users_active_contracts`, payload)).data
      commit('SET_USERS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getUserActiveContract({ commit }, payload) {
    try {
      const resp = (
        await ApiMsProjectsService.get(
          `/active_contract?user_email=${payload.user_email}&date=${payload.date}`
        )
      ).data
      return resp
    } catch (error) {
      return error
    }
  }
}
