import ApiService from "@/state/api.service";

export const state = {
    etapasProyecto: [],
    etapaProyecto: [],
    resultadoCrearEtapaProyecto: {},
    errorEtapaProyecto: null,
};

export const getters = {
    etapasProyecto: (state) => state.etapasProyecto,
    etapaProyecto: (state) => state.etapaProyecto,
    resultadoCrearEtapaProyecto: (state) => state.resultadoCrearEtapaProyecto,
    errorEtapaProyecto: (state) => state.errorEtapaProyecto,
};

export const mutations = {
    setEtapaProyectos(state, newValue) {
        state.etapasProyecto = newValue;
    },
    setEtapaProyecto(state, newValue) {
        state.etapaProyecto = newValue;
    },
    setResultadoCrearEtapaProyecto(state, newValue) {
        state.resultadoCrearEtapaProyecto = newValue;
    },
    setError(state, newValue) {
        state.errorEtapaProyecto = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerEtapasProyecto({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/etapaProyecto`)
                .then((response) => {
                    commit("setEtapaProyectos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerEtapaProyecto({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/etapaProyecto`)
                .then((response) => {
                    commit("setEtapaProyecto", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearEtapaProyecto({ commit }, etapaProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/etapaProyecto`, etapaProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarEtapaProyecto({ commit }, etapaProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/etapaProyecto`, etapaProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarEtapaProyecto({ commit }, etapaProyectoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/etapaProyecto/${etapaProyectoId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
