import ApiService from "@/state/api.service";

export const state = {
    regiones: [],
    comunasRegion: [],
    errorLocalizacion: null,
};

export const getters = {
    regiones: (state) => state.regiones,
    comunasRegion: (state) => state.comunasRegion,
    errorLocalizacion: (state) => state.errorLocalizacion,
};

export const mutations = {
    setRegiones(state, newValue) {
        state.regiones = newValue;
    },
    setComunasRegion(state, newValue) {
        state.comunasRegion = newValue;
    },
    setError(state, newValue) {
        state.errorLocalizacion = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerRegiones({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/localizacion/regiones`)
                .then((response) => {
                    commit("setRegiones", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerComunasRegion({ commit }, codigoRegion) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/localizacion/regiones/${codigoRegion}/comunas`
            )
                .then((response) => {
                    commit("setComunasRegion", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
