import ApiService from "@/state/api.service";

export const state = {
    comentariosUsuario: [],
    resultadoCrearComentario: {},
    errorComentario: null,
};

export const getters = {
    comentariosUsuario: (state) => state.comentariosUsuario,
    resultadoCrearComentario: (state) => state.resultadoCrearComentario,
    errorComentario: (state) => state.errorComentario,
};

export const mutations = {
    setComentariosUsuario(state, newValue) {
        state.comentariosUsuario = newValue;
    },
    setResultadoCrearComentario(state, newValue) {
        state.resultadoCrearComentario = newValue;
    },
    setError(state, newValue) {
        state.errorComentario = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerComentarios({ commit }, comentario) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/comentario/${comentario.codigoLugarInstalacion}/${comentario.codigoProyecto}`
            )
                .then((response) => {
                    commit("setComentariosUsuario", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearComentario({ commit }, comentario) {
        // commit('setResultadoCrearComentario', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/comentario`, comentario)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
