<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "app",
  data() {
    return {
      closedBannerAlert: false,
    };
  },
  methods: {
    ...mapActions({
      getBannerAlertInfo: "auth/getBannerAlertInfo",
    }),
  },
  computed: {
    ...mapGetters({
      bannerAlertInfo: "auth/bannerAlertInfo",
    }),
  },
  watch: {
    $route: {
      handler(newRoute) {
        if (newRoute.name && !this.closedBannerAlert) {
          this.getBannerAlertInfo();
        }
      },
    },
  },
};
</script>

<template>
  <section>
    <b-alert
      @dismissed="closedBannerAlert = true"
      show
      dismissible
      variant="warning"
      fade
      v-if="!closedBannerAlert && bannerAlertInfo.active"
    >
      <span>{{ bannerAlertInfo.text }}</span>
    </b-alert>
    <RouterView />
  </section>
</template>
