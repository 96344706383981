import ApiService from "@/state/api.service";

export const state = {
    actividadTipoProyectoConfiguraciones: [],
    actividadTipoProyectoConfiguracion: [],
    resultadoCrearActividadTipoProyectoConfiguracion: {},
    errorActividadTipoProyectoConfiguracion: null,
};

export const getters = {
    actividadTipoProyectoConfiguraciones: (state) =>
        state.actividadTipoProyectoConfiguraciones,
    actividadTipoProyectoConfiguracion: (state) =>
        state.actividadTipoProyectoConfiguracion,
    resultadoCrearActividadTipoProyectoConfiguracion: (state) =>
        state.resultadoCrearActividadTipoProyectoConfiguracion,
    errorActividadTipoProyectoConfiguracion: (state) =>
        state.errorActividadTipoProyectoConfiguracion,
};
const urlApi = process.env.VUE_APP_APIURL;
export const mutations = {
    setActividadTipoProyectoConfiguraciones(state, newValue) {
        state.actividadTipoProyectoConfiguraciones = newValue;
    },
    setActividadTipoProyectoConfiguracion(state, newValue) {
        state.actividadTipoProyectoConfiguracion = newValue;
    },
    setResultadoCrearActividadTipoProyectoConfiguracion(state, newValue) {
        state.resultadoCrearActividadTipoProyectoConfiguracion = newValue;
    },
    setError(state, newValue) {
        state.errorActividadTipoProyectoConfiguracion = newValue;
    },
};

export const actions = {
    obtenerActividadTipoProyectoConfiguraciones(
        { commit },
        actividadTipoProyectoConfiguracion
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadTipoProyectoConfiguracion`)
                .then((response) => {
                    commit(
                        "setActividadTipoProyectoConfiguraciones",
                        response.data.body
                    );
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearActividadTipoProyectoConfiguracion(
        { commit },
        actividadTipoProyectoConfiguracion
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/actividadTipoProyectoConfiguracion`,
                actividadTipoProyectoConfiguracion
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarActividadTipoProyectoConfiguracion(
        { commit },
        actividadTipoProyectoConfiguracion
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/actividadTipoProyectoConfiguracion`,
                actividadTipoProyectoConfiguracion
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarActividadTipoProyectoConfiguracion(
        { commit },
        actividadTipoProyectoConfiguracionId
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/actividadTipoProyectoConfiguracion/${actividadTipoProyectoConfiguracionId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
