import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router/index'
import "../src/design/app.scss";
import store from '@/state/store'
import App from './App.vue'
import i18n from './i18n'
import ApiService from "@/state/api.service";
import vuetify from '@/plugins/vuetify'


import './plugins'

// API service init
ApiService.init();
Vue.config.productionTip = false
Vue.use(VueRouter)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
