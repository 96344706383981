import BaseService from "../../services/base_service"

import ApiMsApproveRequestMaterialsService from '@/services/ms_approve_request_materials'
import REQUEST_SERVICE_CONSTANTS from "@/constants/requestService";

const deliveryRequestsService = new BaseService(
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_URL,
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_API_KEY
)

export const state = {
    requestServicesQuotes: {
        total_items: 0,
        total_pages: 1,
        items: []
    },
    allSupliers: {
        total: 0,
        number_of_pages: 1,
        items: []
    }
}

export const getters = {
    requestServicesQuotes: (state) => state.requestServicesQuotes,
    allSupliers: (state) => state.allSupliers,
}  

export const mutations = {
    SET_REQUEST_SERVICES_QUOTES(state, payload) {
        state.requestServicesQuotes = payload
    },
    UPDATE_STATUS_SERVICES_REQUEST(state, payload){
        let requestIndex = state.requestServicesQuotes.items.findIndex(item => item.id === payload.request_id)
        state.requestServicesQuotes.items[requestIndex].items.forEach(service => {
          if(service.status === REQUEST_SERVICE_CONSTANTS.service_statuses.in_quotation){
            service.status = REQUEST_SERVICE_CONSTANTS.service_statuses.in_review
          }
        });
    },
    SPLICE_FILE_SERVICE_DELETED(state, payload){
        let requestIndex = state.requestServicesQuotes.items.findIndex(item => item.id === payload.request_id)
        let serviceIndex = state.requestServicesQuotes.items[requestIndex].items.findIndex(item => item.id === payload.service_id)
        state.requestServicesQuotes.items[requestIndex].items[serviceIndex].documents = state.requestServicesQuotes.items[requestIndex].items[serviceIndex].documents.filter(item => {
            return item.id !== payload.document_id
        }) 
    },    
    UPDATE_FILE_SUPPLIER_LETTER(state, payload){
      let requestIndex = state.requestServicesQuotes.items.findIndex(item => item.id === payload.request_id)
      let serviceIndex = state.requestServicesQuotes.items[requestIndex].items.findIndex(item => item.id === payload.service_id)
      state.requestServicesQuotes.items[requestIndex].items[serviceIndex].documents.forEach(item => {
        if(item.id == payload.document_id){
          item.unique_supplier = payload.unique_supplier
        }
      }) 
    },    
    SPLICE_COMMENT_REQUEST_DELETED(state, payload){
      let requestIndex = state.requestServicesQuotes.items.findIndex(item => item.id === payload.request_id)
      state.requestServicesQuotes.items[requestIndex].comments = state.requestServicesQuotes.items[requestIndex].comments.filter(item => {
          return item.id !== payload.comment_id
      })
    },
    SPLICE_COMMENT_SERVICE_DELETED(state, payload){
      let requestIndex = state.requestServicesQuotes.items.findIndex(item => item.id === payload.request_id)
      let serviceIndex = state.requestServicesQuotes.items[requestIndex].items.findIndex(item => item.id === payload.service_id)
      state.requestServicesQuotes.items[requestIndex].items[serviceIndex].comments = state.requestServicesQuotes.items[requestIndex].items[serviceIndex].comments.filter(item => {
          return item.id !== payload.comment_id
      }) 
    },
    SET_SUPPLIERS(state, payload){
      state.allSupliers = payload
    },  
}


export const actions = {
    async getRequestServicesQuotes({ commit }, payload = {}) {
        try {
            const resp = (await ApiMsApproveRequestMaterialsService.get(
                `/api/service_request`, payload)).data
            commit('SET_REQUEST_SERVICES_QUOTES', resp)
            return resp
        } catch (error) {
            return error
        }
    },
    async createCommentService({commit}, payload){
      let queryParams = new URLSearchParams({
        comment: payload.comment,
        item_id: payload.service_id,
        user: payload.user
      })
        try {
          const resp = await ApiMsApproveRequestMaterialsService.post(
            `/api/service_request/comment_item?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error.response
          }
      },
      async createCommentRequest({commit}, payload){
        let queryParams = new URLSearchParams({
          comment: payload.comment,
          request_id: payload.request_id,
          user: payload.user
        })
          try {
            const resp = await ApiMsApproveRequestMaterialsService.post(
              `/api/service_request/comment_request?${queryParams}`, payload)
              return resp
            } catch (error) {
              return error.response
            }
        },
      async deleteCommentService({commit}, payload){
        try {
          const resp = await ApiMsApproveRequestMaterialsService.delete(
            `/api/service_request/delete_item_comment?comment_id=${payload.comment_id}&user=${payload.user}`, payload)
            commit('SPLICE_COMMENT_SERVICE_DELETED', payload)
            return resp
          } catch (error) {
            console.log(error)
            return error.response
          }
      },
      async deleteCommentRequest({commit}, payload){
        try {
          const resp = await ApiMsApproveRequestMaterialsService.delete(
            `/api/service_request/delete_comment?comment_id=${payload.comment_id}&user=${payload.user}`, payload)
            commit('SPLICE_COMMENT_REQUEST_DELETED', payload)
            return resp
          } catch (error) {
            console.log(error)
            return error.response
          }
      },
      async updateService({commit}, payload){
        try {        
          let payloadUpdateFiles = new FormData()
          payloadUpdateFiles.append('body', JSON.stringify({
            item_id: payload.service_id,
            user: payload.user,
            quantity: payload.quantity,
            documents: payload.documents
          }))
          if(payload.files){
            for(let i = 0; i < payload.files.length; i++){
              payloadUpdateFiles.append(`files`, payload.files[i])
            }
          }
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `api/service_request/update_item`,
            payloadUpdateFiles
          )
          return resp
        } catch (error) {
          return error.response
        }
      },
      async updateStatusService({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/service_request/approve_item?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error?.response || error
          }
      },
      async updateStatusServiceToApproveFromPurchase({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/abastecimiento_approve_service_request_item?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error?.response || error
          }
      },
      async updateStatusRequestToApproveFromPurchase({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/abastecimiento_approve_service_request?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error?.response || error
          }
      },
      async updateStatusRequestToPendingApproval({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/service_request/approve_request?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error.response
          }
      },
      async updateStatusRequesToRejection({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/service_request/reject_request?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error.response
          }
      },
      async updateStatusRequestMaverickToPendingApproval({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/approve_maverick_service_request?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error.response
          }
      },
      async updateStatusRequestMaverickToReviewUser({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/send_to_user_revision_maverick?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error.response
          }
      },
      async updateStatusRequestMaverickToRejection({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/reject_maverick_service_request?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error.response
          }
      },
      async updateStatusRequestMaverickToReviewPurchase({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/maverick_user_approve?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error.response
          }
      },
      async updateStatusServiceToQuotation({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/service_request/return_item_to_quotation?${queryParams}`, payload)
            return resp
          } catch (error) {
            return error.response
          }
      },
      async updateStatusRequestToQuotation({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/service_request/return_to_quotation?${queryParams}`, payload)
          return resp
        } catch (error) {
          return error.response
        }
      },
      async updateStatusRequestToReview({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/service_request/check_request?${queryParams}`, payload)
          commit('UPDATE_STATUS_SERVICES_REQUEST', payload)
          return resp
        } catch (error) {
          return error.response
        }
      },
     
      async deleteService({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.delete(
            `/api/service_request/delete_item?${queryParams}`, payload)
          return resp
        } catch (error) {
          return error.response
        }
      },
      async changeSingleSupplierLetterFile({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.put(
            `/api/service_request/mark_unique_supplier?${queryParams}`, payload)
          commit('UPDATE_FILE_SUPPLIER_LETTER', payload)
          return resp
        } catch (error) {
          return error.response
        }
      },
      async deleteFileService({ commit }, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = await ApiMsApproveRequestMaterialsService.delete(
            `/api/service_request/delete_document?${queryParams}`, payload)
          commit('SPLICE_FILE_SERVICE_DELETED', payload)
          return resp
        } catch (error) {
          return error.response
        }
      },
      async getSuppliers({ commit }, payload) {
        try {
          const resp = (await deliveryRequestsService.get(
            `/api/supplier_creation/sap_suppliers`, payload)).data
          commit('SET_SUPPLIERS', {
            ...resp,
            items: resp.items.map(item => {
              return {
                ...item,
                origin: "SAP"
              }
            })
          })
          return resp
        } catch (error) {
          return error
        } 
      },
      async getServicesDeleted(_, payload) {
        let queryParams = new URLSearchParams({...payload})
        try {
          const resp = (await ApiMsApproveRequestMaterialsService.get(
            `/api/service_request/get_deleted_items?${queryParams}`, {})).data
          return resp
        } catch (error) {
          return []
        } 
      },
      async getExchangeRate(_, payload = {}) {
        try {
          let queryParams = new URLSearchParams({...payload})
          const resp = (await ApiMsApproveRequestMaterialsService.get(
            `/api/get_exchange_rate?${queryParams}`, {})).data
          return resp
        } catch (error) {
          return error
        }
      },
      clearAllSuppliers({ commit }) {
        commit('SET_SUPPLIERS', {
          total: 0,
          number_of_pages: 1,
          items: []
        })
      },
      setSuppliersExternal({ commit }, payload) {
        commit('SET_SUPPLIERS', {
          ...payload,
          items: payload.items.map(item => {
          return {
            ...item,
            code: item.rut,
            name: item.official_name,
            emai: item.contact_user_email,
            origin: "Formulario proveedores"
          }
        })
        })
      }
}