import ApiService from "@/state/api.service";

export const state = {
    contactos: [],
    contacto: [],
    resultadoCrearContacto: {},
    errorContacto: null,
};

export const getters = {
    contactos: (state) => state.contactos,
    contacto: (state) => state.contacto,
    resultadoCrearContacto: (state) => state.resultadoCrearContacto,
    errorContacto: (state) => state.errorContacto,
};

export const mutations = {
    setContactos(state, newValue) {
        state.contactos = newValue;
    },
    setContacto(state, newValue) {
        state.contacto = newValue;
    },
    setResultadoCrearContacto(state, newValue) {
        state.resultadoCrearContacto = newValue;
    },
    setError(state, newValue) {
        state.errorContacto = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerContactos({ commit }, contacto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/contacto/${contacto.codigoLugarInstalacion}/${contacto.codigoProyecto}`
            )
                .then((response) => {
                    commit("setContactos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerContacto({ commit }, contacto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/contacto/lugarInstalacionProyectoEmail/${contacto.codigoLugarInstalacion}/${contacto.codigoProyecto}/${contacto.email}`
            )
                .then((response) => {
                    commit("setContacto", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearContacto({ commit }, contacto) {
        // commit('setResultadoCrearContacto', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/contacto`, contacto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearContactosMasivo({ commit }, contactos) {
        // commit('setResultadoCrearContacto', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/contacto/crearMasivo`, contactos)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarContacto({ commit }, contactoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/contacto/id/${contactoId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
