import ApiService from "@/state/api.service";

export const state = {
    tipoDocumentos: [],
    tipoDocumento: [],
    resultadoCrearTipoDocumento: {},
    errorTipoDocumento: null,
};

export const getters = {
    tipoDocumentos: (state) => state.tipoDocumentos,
    tipoDocumento: (state) => state.tipoDocumento,
    resultadoCrearTipoDocumento: (state) => state.resultadoCrearTipoDocumento,
    errorTipoDocumento: (state) => state.errorTipoDocumento,
};

export const mutations = {
    setTipoDocumentos(state, newValue) {
        state.tipoDocumentos = newValue;
    },
    setTipoDocumento(state, newValue) {
        state.tipoDocumento = newValue;
    },
    setResultadoCrearTipoDocumento(state, newValue) {
        state.resultadoCrearTipoDocumento = newValue;
    },
    setError(state, newValue) {
        state.errorTipoDocumento = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerTipoDocumentos({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/tipoDocumento`)
                .then((response) => {
                    commit("setTipoDocumentos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearTipoDocumento({ commit }, tipoDocumento) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/tipoDocumento`, tipoDocumento)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarTipoDocumento({ commit }, tipoDocumento) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/tipoDocumento`, tipoDocumento)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarTipoDocumento({ commit }, tipoDocumentoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/tipoDocumento/${tipoDocumentoId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
