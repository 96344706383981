import ApiService from "@/state/api.service";

export const state = {
    tipoInstalaciones: [],
    tipoInstalacion: [],
    resultadoCrearTipoInstalacion: {},
    errorTipoInstalacion: null,
};

export const getters = {
    tipoInstalaciones: (state) => state.tipoInstalaciones,
    tipoInstalacion: (state) => state.tipoInstalacion,
    resultadoCrearTipoInstalacion: (state) =>
        state.resultadoCrearTipoInstalacion,
    errorTipoInstalacion: (state) => state.errorTipoInstalacion,
};

export const mutations = {
    setTipoInstalaciones(state, newValue) {
        state.tipoInstalaciones = newValue;
    },
    setTipoInstalacion(state, newValue) {
        state.tipoInstalacion = newValue;
    },
    setResultadoCrearTipoInstalacion(state, newValue) {
        state.resultadoCrearTipoInstalacion = newValue;
    },
    setError(state, newValue) {
        state.errorTipoInstalacion = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerTipoInstalaciones({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/tipoInstalacion`)
                .then((response) => {
                    commit("setTipoInstalaciones", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearTipoInstalacion({ commit }, tipoInstalacion) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/tipoInstalacion`, tipoInstalacion)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarTipoInstalacion({ commit }, tipoInstalacion) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/tipoInstalacion`, tipoInstalacion)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarTipoInstalacion({ commit }, tipoInstalacionId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/tipoInstalacion/${tipoInstalacionId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
