import ApiMsProjectsService from '@/services/ms_projects_service'

export const state = {
  loading: false,
  dashboards: [],
  dashboardsFromType: []
}

export const getters = {
  loading: (state) => state.loading,
  dashboards: (state) => state.dashboards,
  dashboardsFromType: (state) => state.dashboardsFromType
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_DASHBOARDS(state, payload) {
    state.dashboards = payload
  },
  SET_DASHBOARDS_FROM_TYPE(state, payload) {
    state.dashboardsFromType = payload
  }
}

export const actions = {
  async getDashboards({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = (
        await ApiMsProjectsService.get(
          `/smartsheet/filter_smartsheet`,
          payload
        )
      ).data
      commit('SET_DASHBOARDS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getDashboardsFromType({commit}, payload){
    commit('SET_LOADING', true)
    try {
      const resp = (
        await ApiMsProjectsService.get(
          `/smartsheet/filter_smartsheet`,
          payload
        )
      ).data
      commit('SET_DASHBOARDS_FROM_TYPE', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async addDashboard({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.post(`/smartsheet/smartsheet`, payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async deleteDashboard({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.delete(
        `/smartsheet/delete_smartsheet/${payload.smartsheet_id}?smartsheet_id=${payload.smartsheet_id}&user=${payload.user}`
      )
      return resp
    } catch (error) {
      return error
    }
  }
}
