import ApiService from "@/state/api.service";

export const state = {
    actividadProyectoAvanceDiarios: [],
    actividadProyectoAvanceDiario: [],
    resultadoCrearActividadProyectoAvanceDiario: {},
    errorActividadProyectoAvanceDiario: null,
};

export const getters = {
    actividadProyectoAvanceDiarios: (state) =>
        state.actividadProyectoAvanceDiarios,
    actividadProyectoAvanceDiario: (state) =>
        state.actividadProyectoAvanceDiario,
    resultadoCrearActividadProyectoAvanceDiario: (state) =>
        state.resultadoCrearActividadProyectoAvanceDiario,
    errorActividadProyectoAvanceDiario: (state) =>
        state.errorActividadProyectoAvanceDiario,

    actividadProyectoAvanceDiarioDetalles: (state) =>
        state.actividadProyectoAvanceDiarioDetalles,
    actividadProyectoAvanceDiarioDetalle: (state) =>
        state.actividadProyectoAvanceDiarioDetalle,
    resultadoCrearActividadProyectoAvanceDiarioDetalle: (state) =>
        state.resultadoCrearActividadProyectoAvanceDiarioDetalle,
    errorActividadProyectoAvanceDiarioDetalle: (state) =>
        state.errorActividadProyectoAvanceDiarioDetalle,
};

export const mutations = {
    setActividadProyectoAvanceDiarios(state, newValue) {
        state.actividadProyectoAvanceDiarios = newValue;
    },
    setActividadProyectoAvanceDiario(state, newValue) {
        state.actividadProyectoAvanceDiario = newValue;
    },
    setResultadoCrearActividadProyectoAvanceDiario(state, newValue) {
        state.resultadoCrearActividadProyectoAvanceDiarioDetalle = newValue;
    },
    setError(state, newValue) {
        state.errorActividadProyectoAvanceDiario = newValue;
    },
    setActividadProyectoAvanceDiarioDetalles(state, newValue) {
        state.actividadProyectoAvanceDiarios = newValue;
    },
    setActividadProyectoAvanceDiarioDetalle(state, newValue) {
        state.actividadProyectoAvanceDiario = newValue;
    },
    setResultadoCrearActividadProyectoAvanceDiarioDetalle(state, newValue) {
        state.resultadoCrearActividadProyectoAvanceDiario = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerActividadProyectoAvanceDiarios(
        { commit },
        actividadProyectoAvanceDiario
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyectoAvanceDiario/${actividadProyectoAvanceDiario.codigoLugarInstalacion}/${actividadProyectoAvanceDiario.codigoProyecto}`
            )
                .then((response) => {
                    commit(
                        "setActividadProyectoAvanceDiarios",
                        response.data.body
                    );
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerActividadProyectoAvanceDiariosFecha(
        { commit },
        actividadProyectoAvanceDiario
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyectoAvanceDiario/fecha/${actividadProyectoAvanceDiario.codigoLugarInstalacion}/${actividadProyectoAvanceDiario.codigoProyecto}/${actividadProyectoAvanceDiario.fecha}`
            )
                .then((response) => {
                    commit(
                        "setActividadProyectoAvanceDiarios",
                        response.data.body
                    );
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerActividadProyectoAvanceDiarioId(
        { commit },
        actividadProyectoAvanceDiarioId
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyectoAvanceDiario/id/${actividadProyectoAvanceDiarioId}`
            )
                .then((response) => {
                    commit(
                        "setActividadProyectoAvanceDiario",
                        response.data.body
                    );
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearActividadProyectoAvanceDiario(
        { commit },
        actividadProyectoAvanceDiario
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/actividadProyectoAvanceDiario`,
                actividadProyectoAvanceDiario
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarActividadProyectoAvanceDiario(
        { commit },
        actividadProyectoAvanceDiario
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/actividadProyectoAvanceDiario`,
                actividadProyectoAvanceDiario
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarActividadProyectoAvanceDiario(
        { commit },
        actividadProyectoAvanceDiario
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/actividadProyectoAvanceDiario/id/${actividadProyectoAvanceDiario.actividadProyectoAvanceDiarioId}/${actividadProyectoAvanceDiario.actividadProyectoId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },



    obtenerActividadProyectoAvanceDiarioDetalles(
        { commit },
        actividadProyectoAvanceDiario
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyectoAvanceDiario/detalle/${actividadProyectoAvanceDiario.codigoLugarInstalacion}/${actividadProyectoAvanceDiario.codigoProyecto}`
            )
                .then((response) => {
                    commit(
                        "setActividadProyectoAvanceDiarioDetalles",
                        response.data.body
                    );
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerAcumuladoActividad(
        { commit },
        actividadProyectoId
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyectoAvanceDiario/detalle/actividad/id/${actividadProyectoId}`
            )
                .then((response) => {
                    commit(
                        "setActividadProyectoAvanceDiarioDetalle",
                        response.data.body
                    );
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerActividadProyectoAvanceDiarioDetalleActividad(
        { commit },
        actividadProyectoAvanceDiarioDetalle
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyectoAvanceDiario/detalle/actividad/${actividadProyectoAvanceDiarioDetalle.actividadProyectoAvanceDiarioId}/${actividadProyectoAvanceDiarioDetalle.actividadProyectoId}`
            )
                .then((response) => {
                    commit(
                        "setActividadProyectoAvanceDiarioDetalle",
                        response.data.body
                    );
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerActividadProyectoAvanceDiarioDetalleId(
        { commit },
        actividadProyectoAvanceDiarioDetalleId
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyectoAvanceDiario/detalle/id/${actividadProyectoAvanceDiarioDetalleId}`
            )
                .then((response) => {
                    commit(
                        "setActividadProyectoAvanceDiarioDetalle",
                        response.data.body
                    );
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearActividadProyectoAvanceDiarioDetalle(
        { commit },
        actividadProyectoAvanceDiario
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/actividadProyectoAvanceDiario/detalle`,
                actividadProyectoAvanceDiario
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarActividadProyectoAvanceDiarioDetalle(
        { commit },
        actividadProyectoAvanceDiario
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/actividadProyectoAvanceDiario/detalle`,
                actividadProyectoAvanceDiario
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarActividadProyectoAvanceDiarioDetalle(
        { commit },
        actividadProyectoAvanceDiarioDetalleId
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/actividadProyectoAvanceDiario/detalle/${actividadProyectoAvanceDiarioDetalleId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
