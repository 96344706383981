import ApiMsProjectsService from '@/services/ms_projects_service'
import BaseService from "../../services/base_service"

const requestMaterialsService = new BaseService(
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_URL,
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_API_KEY
)

export const state = {
  loading: false,
  loadingSyncProjects: false,
  projects: [],
  filteredProjects: [],
  places: [],
  specialPanels: [],
  stagesProjects: [],
  projectInfo: {},
  currentProject: null,
}

export const getters = {
  loading: (state) => state.loading,
  projects: (state) => state.projects,
  stagesProjects: (state) => state.stagesProjects,
  filteredProjects: (state) => state.filteredProjects,
  places: (state) => state.places,
  specialPanels: (state) => state.specialPanels,
  loadingSyncProjects: (state) => state.loadingSyncProjects,
  projectInfo: (state) => state.projectInfo,
  currentProject: (state) => state.currentProject,
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_PROJECTS(state, payload) {
    state.projects = payload.map((item) => ({
      ...item,
      custom_code: `${item.code} - ${item.name}`
    }))
  },
  SET_CURRENT_PROJECT(state, payload) {
    state.currentProject = payload
  },
  SET_STAGES_PROJECTS(state, payload) {
    state.stagesProjects = payload.stages
  },
  SET_PROJECT_INFO(state, payload) {
    state.projectInfo = payload.project
  },
  SET_FILTERED_PROJECTS(state, payload) {
    state.filteredProjects = payload
  },
  SET_PLACES(state, payload) {
    let places = []
    payload.forEach((project) => {
      places = [...places, ...project.places]
    })
    state.places = places
  },
  SET_NEW_SPECIAL_PANEL(state, payload){
    state.specialPanels.unshift(payload)
  },
  SET_SPECIAL_PANELS(state, payload) {
    state.specialPanels = payload
  },
  SET_LOADING_SYNC_PROJECTS(state, payload){
    state.loadingSyncProjects = payload
  },
}

export const actions = {
  async getAllProjects({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(`/all_projects`, payload)).data
      commit('SET_PROJECTS', resp)
      commit('SET_PLACES', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getProjectFromCode({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(`/get_project/${payload.code}`)).data
      commit('SET_CURRENT_PROJECT', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getStagesProjects({ commit }, payload = {}) {
    try {
      const resp = (await requestMaterialsService.get(`/api/requests/get_all_stages`, payload)).data
      commit('SET_STAGES_PROJECTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getProjectInfo({ commit }, payload = {}) {
    try {
      const resp = (await requestMaterialsService.get(`/api/requests/get_project_info`, payload)).data
      commit('SET_PROJECT_INFO', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async syncProjects({commit}){
    try {
      commit('SET_LOADING_SYNC_PROJECTS', true)
      await requestMaterialsService.post(
        `/api/maintenance/manual_project_maintenance`
      )
      const resp = await ApiMsProjectsService.post(
        `/synchronize_db`
      )
      return resp
    } catch (error) {
      return error.resp
    } finally {
      commit('SET_LOADING_SYNC_PROJECTS', false)
    }
  },
  async getSpecialPanels({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(`/special_panel_list`, payload)).data
      commit('SET_SPECIAL_PANELS', resp.panels)
      return resp
    } catch (error) {
      console.log(error)
      return error
    }
  },
  setNewSpecialPanel({commit}, payload){
    commit('SET_NEW_SPECIAL_PANEL', payload)
  },
  async getFilteredProjects({ commit }, payload = {}) {
    commit('SET_LOADING', true)
    try {
      const resp = (await ApiMsProjectsService.get(`/filtered_projects`, payload)).data
      commit('SET_FILTERED_PROJECTS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  cleanFilteredProjects({ commit }) {
    commit('SET_FILTERED_PROJECTS', [])
  }
}
