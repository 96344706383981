import ApiService from "@/state/api.service";

export const state = {
    usuarioBodegas: [],
    usuarioBodega: [],
    resultadoCrearUsuarioBodega: {},
    errorUsuarioBodega: null,
};

export const getters = {
    usuarioBodegas: (state) => state.usuarioBodegas,
    usuarioBodega: (state) => state.usuarioBodega,
    resultadoCrearUsuarioBodega: (state) => state.resultadoCrearUsuarioBodega,
    errorUsuarioBodega: (state) => state.errorUsuarioBodega,
};

export const mutations = {
    setUsuarioBodegas(state, newValue) {
        state.usuarioBodegas = newValue;
    },
    setUsuarioBodega(state, newValue) {
        state.usuarioBodega = newValue;
    },
    setResultadoCrearUsuarioBodega(state, newValue) {
        state.resultadoCrearUsuarioBodega = newValue;
    },
    setError(state, newValue) {
        state.errorUsuarioBodega = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerUsuarioBodegas({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/usuarioBodega`)
                .then((response) => {
                    commit("setUsuarioBodegas", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerUsuarioBodegasTokenUsuario({ commit }, tokenUsuario) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/usuarioBodega/token/${tokenUsuario}`)
                .then((response) => {
                    commit("setUsuarioBodega", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerUsuarioBodega({ commit }, email_usuario) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/usuarioBodega/email/${email_usuario}`)
                .then((response) => {
                    commit("setUsuarioBodega", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearUsuarioBodega({ commit }, usuarioBodega) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/usuarioBodega`, usuarioBodega)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actulizarUsuarioBodega({ commit }, usuarioBodega) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/usuarioBodega`, usuarioBodega)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    activarDesactivarUsuarioBodega({ commit }, usuarioBodega) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/usuarioBodega/activarDesactivar`,
                usuarioBodega
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarUsuarioBodega({ commit }, usuario_bodega_id) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/usuarioBodega/${usuario_bodega_id}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
