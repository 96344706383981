import Vue from 'vue'

import vco from "v-click-outside"
import Scrollspy from 'vue2-scrollspy';
const VueScrollTo = require('vue-scrollto')
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2';
import VueAxios from "vue-axios";
import axios from "axios";
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocialauth from "vue-social-auth";
import VueDatePicker from '@mathieustan/vue-datepicker';

Vue.use(Scrollspy);
Vue.use(VueScrollTo)
Vue.use(vco)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB5qYyvvDhdNuAikGK2QdiTKry2rZ1q5Ww',
        libraries: 'places',
    },
    installComponents: true
})

Vue.use(VueSocialauth, {
    providers: {
      google: {
        clientId:process.env.VUE_APP_CLIENTID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        redirectUri:process.env.VUE_APP_REDIRECT_URI,
      }
    }
  });
  
// Options: You can set lang by default
Vue.use(VueDatePicker, {
    lang: 'es'
  });
  
  

