export const getIconMaterialFromExtension = (extension) => {
    if (extension == "doc" || extension == "docx") {
        return "mdi-file-word text-primary";
      } else if (
        extension == "xls" ||
        extension == "xlsx" ||
        extension == "csv"
      ) {
        return "mdi-file-excel text-success";
      } else if (extension == "pdf") {
        return "mdi-file-pdf text-danger";
      } else if (extension == "zip") {
        return "mdi-folder-zip text-warning";
      } else if (extension == "doc" || extension == "docx") {
        return "mdi-file-document text-primary";
      } else if (
        extension == "jpg" ||
        extension == "jpeg" ||
        extension == "png" ||
        extension == "bmp"
      ) {
        return "mdi-image-area text-danger";
      } else {
        return "mdi-file";
      }
}

export const flattenFolders = (folders) => {
  let result = [];
  function flatten(folder) {
    if(!folder.documents){
      result.push({ id: folder.id, name: folder.name });
    }
    if (folder.children) {
      folder.children.forEach(flatten);
    }
  }

  folders.forEach(flatten);
  return result;
}

export const setFileFolderToChildrenFolder = (folders, filters) => {
  let foldersFiltered = folders;

  if(filters.document_type_filter){
    foldersFiltered = folders.filter((folder) => {
      return folder.folder_document_types.find((document_type) => {
        return document_type.document_type.name == filters.document_type_filter
      }).documents.length > 0
    })
  }
  function setFileFolderToChildrenFolder(folder) {
    if(folder.folder_document_types?.length){
      let documentsFolder = []
      folder.folder_document_types.forEach((document_type) => {
        document_type.documents.forEach((document) => {
          if(document.status){
            documentsFolder.push({...document, name: document.file_name, document_type: {...document_type.document_type}});
          }
        })
      });
      if(documentsFolder.length){
        folder.children.push({
          id: `${new Date().getTime() + Math.floor(Math.random() * 1000)}`,
          children: null,
          documents: documentsFolder,
        })
      }
    }
    if (folder.children?.length) {
      folder.children.forEach((child) => {
        setFileFolderToChildrenFolder(child);
      });
    }
  }
  foldersFiltered.forEach((folder) => {
    setFileFolderToChildrenFolder(folder);
  });
  return foldersFiltered;
};  

export const setDocumentsTypesFromFolders = (folders) => {
  let result = []
  function setDocumentsTypesFromFolder(folder) {
    if(folder.folder_document_types?.length){
      folder.folder_document_types.forEach((document_type) => {
        result.push({...document_type.document_type});
      });
    }
    if (folder.children?.length) {
      folder.children.forEach((child) => {
        setDocumentsTypesFromFolder(child);
      });
    }
  }
  folders.forEach((folder) => {
    setDocumentsTypesFromFolder(folder);
  });
  return result;
};  