import ApiService from "@/state/api.service";

export const state = {
    actividadesProyectoPersonalObra: [],
    actividadProyectoPersonalObra: [],
    resultadoCrearActividadProyectoPersonalObra: {},
    errorActividadProyectoPersonalObra: null,
};

export const getters = {
    actividadesProyectoPersonalObra: state => state.actividadesProyectoPersonalObra,
    actividadProyectoPersonalObra: state => state.actividadProyectoPersonalObra,
    resultadoCrearActividadProyectoPersonalObra: state => state.resultadoCrearActividadProyectoPersonalObra,
    errorActividadProyectoPersonalObra: state => state.errorActividadProyectoPersonalObra,
};

export const mutations = {
    setActividadesProyectoPersonalObra(state, newValue) {
        state.actividadesProyectoPersonalObra = newValue
    },
    setActividadProyectoPersonalObra(state, newValue) {
        state.actividadProyectoPersonalObra = newValue
    },
    setResultadoCrearActividadProyectoPersonalObra(state, newValue) {
        state.resultadoCrearActividadProyectoPersonalObra = newValue
    },
    setError(state, newValue) {
        state.errorActividadProyectoPersonalObra = newValue
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerPersonal({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/obtenerPersonal`).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObra({ commit },actividadProyectoPersonalObra) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObra/${actividadProyectoPersonalObra.codigoLugarInstalacion}/${actividadProyectoPersonalObra.codigoProyecto}`).then((response) => {
                commit('setActividadesProyectoPersonalObra', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObraFecha({ commit },actividadProyectoPersonalObra) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObra/fecha/${actividadProyectoPersonalObra.codigoLugarInstalacion}/${actividadProyectoPersonalObra.codigoProyecto}/${actividadProyectoPersonalObra.fecha}`).then((response) => {
                commit('setActividadesProyectoPersonalObra', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObraId({ commit },actividadProyectoPersonalObraId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObra/id/${actividadProyectoPersonalObraId}`).then((response) => {
                commit('setActividadProyectoPersonalObra', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObraActividad({ commit },actividadProyectoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObra/actividad/${actividadProyectoId}`).then((response) => {
                commit('setActividadProyectoPersonalObra', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    crearActividadProyectoPersonalObra({commit},actividadProyectoPersonalObra) {
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`${urlApi}/actividadProyectoPersonalObra`,actividadProyectoPersonalObra).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                    reject(error);
                })
            });
         
    },
    actualizarActividadProyectoPersonalObra({commit},actividadProyectoPersonalObra) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/actividadProyectoPersonalObra`,actividadProyectoPersonalObra).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    eliminarActividadProyectoPersonalObra({commit},actividadProyectoPersonalObraId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/actividadProyectoPersonalObra/id/${actividadProyectoPersonalObraId}`).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
};
