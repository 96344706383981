import ApiService from "@/state/api.service";

export const state = {
    rolModuloPermisos: [],
    rolModuloPermiso: [],
    resultadoCrearRolModuloPermiso: {},
    errorRolModuloPermiso: null,
};

export const getters = {
    rolModuloPermisos: (state) => state.rolModuloPermisos,
    rolModuloPermiso: (state) => state.rolModuloPermiso,
    resultadoCrearRolModuloPermiso: (state) =>
        state.resultadoCrearRolModuloPermiso,
    errorRolModuloPermiso: (state) => state.errorRolModuloPermiso,
};

export const mutations = {
    setRolModuloPermisos(state, newValue) {
        state.rolModuloPermisos = newValue;
    },
    setRolModuloPermiso(state, newValue) {
        state.rolModuloPermiso = newValue;
    },
    setResultadoCrearRolModuloPermiso(state, newValue) {
        state.resultadoCrearRolModuloPermiso = newValue;
    },
    setError(state, newValue) {
        state.errorRolModuloPermiso = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerRolModuloPermisos({ commit }, rolId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/rolModuloPermiso/rol/${rolId}`)
                .then((response) => {
                    commit("setRolModuloPermisos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerRolModuloPermisosActivos({ commit }, rolId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/rolModuloPermiso/rol/activos/${rolId}`)
                .then((response) => {
                    commit("setRolModuloPermisos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearRolModuloPermiso({ commit }, rolModuloPermiso) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/rolModuloPermiso`, rolModuloPermiso)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarRolModuloPermiso({ commit }, rolModuloPermiso) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/rolModuloPermiso`, rolModuloPermiso)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarRolModuloPermiso({ commit }, modulo) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/rolModuloPermiso/${modulo.codigo_rol}/${modulo.modulo_permiso_id}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
