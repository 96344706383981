import Vue from 'vue'

import LoadingFlux from "@/components/commons/LoadingFlux";
import tinymce from 'vue-tinymce-editor'
import VueDatepickerUi from 'vue-datepicker-ui'
import VueApexCharts from 'vue-apexcharts'

Vue.component('LoadingFlux', LoadingFlux);
Vue.component('Datepicker', VueDatepickerUi)
Vue.component('tinymce', tinymce)
Vue.component('apexchart', VueApexCharts)
