import ApiService from "@/state/api.service";

export const state = {
    imagenes: [],
    errorGaleriaImagenes: null,
};

export const getters = {
    imagenes: (state) => state.imagenes,
    errorGaleriaImagenes: (state) => state.errorGaleriaImagenes,
};

export const mutations = {
    setImagenes(state, newValue) {
        state.imagenes = newValue;
    },
    setError(state, newValue) {
        state.errorGaleriaImagenes = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerImagenes({ commit }, galeria) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/imagenLugarInstalacion/${galeria.codigoLugarInstalacion}/${galeria.codigoProyecto}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
