import BaseService from "../../services/base_service"
import {getStockFromMaterialWarehouse, getPriceFromMaterial} from '@/helpers/requestMaterial'

const requestMaterialsService = new BaseService(
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_URL,
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_API_KEY
)

export const state = {
  loading: false,
  loadingMaterials: false,
  transferMaterials: {
    total_items: 0,
    total_pages: 1,
    items: []
  },
  materialsFromMobileWarehouse: {
    total_items: 0,
    total_pages: 1,
    items: []
  },
  mobileWarehouses: []
}

export const getters = {
  loading: (state) => state.loading,
  loadingMaterials: (state) => state.loadingMaterials,
  transferMaterials: (state) => state.transferMaterials,  
  mobileWarehouses: (state) => state.mobileWarehouses,
  materialsFromMobileWarehouse: (state) => state.materialsFromMobileWarehouse  
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_LOADING_MATERIALS(state, payload) {
    state.loadingMaterials = payload
  },
  SET_TRANSFER_MATERIALS(state, payload){
    state.transferMaterials = payload
  },
  SET_MATERIALS_FROM_MOBILE_WAREHOUSE(state, {payload, warehouse_code}){
    state.materialsFromMobileWarehouse = payload
    state.materialsFromMobileWarehouse.items = payload.items.map(item => {
      return {
        ...item,
        available_quantity: getStockFromMaterialWarehouse(item, warehouse_code),
        price: getPriceFromMaterial(item),
        request: null
      }
    })
  },
  SET_MOBILE_WAREHOUSES(state, payload){
    state.mobileWarehouses = payload.items.map(item => ({
      ...item,
      name_code: `${item.code} - ${item.name}`
    }) 
    )
  },
  CLEAR_MATERIALS(state, payload){
    state.materialsFromMobileWarehouse = payload
  }
}

export const actions = {
  async getTransferMaterials({ commit }, payload = {}) {
    commit('SET_LOADING', true)
    try {
      const resp = (await requestMaterialsService.get(
        `/api/requests/get_requests`, payload)).data
      commit('SET_TRANSFER_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async createTransferMaterials({ commit }, payload) {
    try {
      const resp = await requestMaterialsService.post(
        '/api/bodega_movil/stock_transfer/bodegas', payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateStockMobileWarehouses({ commit }, payload = {}) {
    commit('SET_LOADING_MATERIALS', true)
    try {
      const resp = (await requestMaterialsService.get(
        `/api/bodega_movil/get_material_movil_stock`, payload)).data
      commit('SET_MATERIALS_FROM_MOBILE_WAREHOUSE', {payload: resp,warehouse_code: payload.warehouse_code})
      return resp
    } catch (error) {
      return error
    }
  },
  async getMaterialsFromMobileWarehouse({ commit }, payload = {}) {
    commit('SET_LOADING_MATERIALS', true)
    try {
      const resp = (await requestMaterialsService.get(
        `/api/bodega_movil/get_material_movil`, payload)).data
      commit('SET_MATERIALS_FROM_MOBILE_WAREHOUSE', {payload: resp,warehouse_code: payload.warehouse_code})
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING_MATERIALS', false)
    }
  },
  async getMobileWarehouses({ commit }, payload = {}) {
    try {
      const resp = (await requestMaterialsService.get(
        `/api/bodega_movil/get_bodegas_movil`, payload)).data
      commit('SET_MOBILE_WAREHOUSES', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  clearMaterialsFromMobileWarehouse({commit}){
    commit('CLEAR_MATERIALS', [])
  }
}
