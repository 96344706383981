import ApiMsReturnMaterialsService from '@/services/ms_return_materials_service'
import { getMappedReturnMaterial } from '@/helpers/requestMaterial'

export const state = {
  loading: false,
  loadingRequests: false,
  loadingMaterialsRequest: false,
  requestMaterials: [],
  materialsAvailable: [],
}

export const getters = {
  loading: (state) => state.loading,
  loadingRequests: (state) => state.loadingRequests,
  loadingMaterialsRequest: (state) => state.loadingMaterialsRequest,
  requestMaterials: (state) => state.requestMaterials,
  materialsAvailable: (state) => state.materialsAvailable,
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_LOADING_REQUESTS(state, payload) {
    state.loadingRequests = payload
  },
  SET_LOADING_MATERIALS_REQUEST(state, payload) {
    state.loadingMaterialsRequest = payload
  },
  SET_MATERIALS_AVAILABLE(state, payload) {
    state.materialsAvailable = payload
  },
  SET_REQUEST_MATERIALS(state, payload){
    state.requestMaterials = payload
    if(state.requestMaterials.materials){
      state.requestMaterials.materials = state.requestMaterials.materials.map(getMappedReturnMaterial)
    }
  },
}

export const actions = {
  //Mandar query param is_sap cuando sea de sap
  async loadMaterialsFromErp({commit}, payload){
    try {
        const resp = (await ApiMsReturnMaterialsService.get(`/api/place/projects_materials`, payload)).data
        return resp
      } catch (error) {
        return error
      }
  },
  async getMaterialsAvailableFromProject({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsReturnMaterialsService.get(`/api/place/filter_project_materials`, payload)).data
      commit('SET_MATERIALS_AVAILABLE', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getMaterialsFromRequestReturn({ commit }, payload = {}) {
    commit('SET_LOADING_MATERIALS_REQUEST', true)
    try {
      const resp = (await ApiMsReturnMaterialsService.get(`/api/return/filter_return_materials`, payload)).data
      commit('SET_REQUEST_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING_MATERIALS_REQUEST', false)
    }
  },
  async getRequestsReturnMaterialsFromProject({ commit }, payload = {}) {
    commit('SET_LOADING_REQUESTS', true)
    try {
      const resp = (await ApiMsReturnMaterialsService.get(`/api/return/filter_returns`, payload)).data
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING_REQUESTS', false)
    }
  },
  async sendRequestReturnMaterial({commit}, payload){
    //Mandar body is_sap cuando sea de sap
    try {
      const resp = await ApiMsReturnMaterialsService.post(`/api/return/post_return_materials`, payload)
      return resp
    } catch (error) {
      return error && error.response ? error.response : error
    }
  },
  async approveOrRejectReturnRequestMaterials({commit}, payload){
    try {
      const resp = await ApiMsReturnMaterialsService.post(`/api/return/approve_return`, payload)
      return resp
    } catch (error) {
      return error && error.response ? error.response : error
    }
  },
  async getDispatchGuideFromReturnMaterialRequest({commit}, payload){
    try {
      // const resp = await ApiMsReturnMaterialsService.get(`/api/return/dispatch_guide`, payload)
      // return resp
      return false
    } catch (error) {
      return error && error.response ? error.response : error
    }
  },
  cleanMaterialsAvailable({ commit }) {
    commit('SET_MATERIALS_AVAILABLE', [])
  },
  cleanRequestMaterials({ commit }) {
    commit('SET_REQUEST_MATERIALS', [])
  },
}
