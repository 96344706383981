import { TYPES_EXECUTION_MATERIALS } from '../constants/cubage'

export const getMappedDocumentMaterial = (documentMaterial) => {
  let available_stock = documentMaterial.stock;
  return {
    ...documentMaterial,
    total_cost:
      documentMaterial.quantity && documentMaterial.cost
        ? documentMaterial.quantity * documentMaterial.cost
        : 0,
    available_stock: available_stock >= 0 ? available_stock : 0,
    material: documentMaterial.material
      ? {
          ...documentMaterial.material,
          unit_cost: documentMaterial.cost,
          stock: documentMaterial.material.stocks.length
            ? documentMaterial.material.stocks[documentMaterial.material.stocks.length - 1]
                .quantity
            : 0
        }
      : {
          ...documentMaterial.custommaterial,
          code: '-',
          unit_cost: documentMaterial.cost,
          stock: 0,
          favorite: false
        }
  }
}

const calculateTotalCostMaterialExecution = (item) => {
    let total = 0
    if(item.data){
      switch (item.service_type) {
        case TYPES_EXECUTION_MATERIALS.transport:
          total = item.data.Unit_Cost * item.data.Quantity * item.data.Days
          break
        case TYPES_EXECUTION_MATERIALS.load_transport:
          total = item.data.Unit_Cost * item.data.Quantity
          break
        case TYPES_EXECUTION_MATERIALS.machinery:
          total = item.data.Unit_Cost * item.data.Quantity * item.data.Hour * item.data.Day_Month
          break
        case TYPES_EXECUTION_MATERIALS.labor:
          total = item.data.Unit_Cost * item.data.Staff * item.data.Days
          break  
        case TYPES_EXECUTION_MATERIALS.lodging:
          total = item.data.Unit_Cost * item.data.Staff * item.data.Days
          break
        case TYPES_EXECUTION_MATERIALS.others:
          total = item.data.Unit_Cost * item.data.Quantity
          break  
        default:
          break
      }
    }
    return total
}
export const getMappedMaterialExecution = (item) => {
  return {
    ...item,
    ...item.data,
    total_cost: !item.pre_created ? calculateTotalCostMaterialExecution(item) : null
  }
}

export const getMappedCubageOtherAttachment = (item) => {
  return {
    ...item,
    total_cost: item.unit_cost * item.quantity
  }
}

export const getMappedCubageBoardPanels = (boardItem) => {
  return {
    ...boardItem,
    unit_cost: boardItem.panel.cost,
    total_cost:
      boardItem.quantity && boardItem.panel.cost
        ? boardItem.quantity * boardItem.panel.cost
        : 0
  }
}