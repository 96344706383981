import ApiMsProjectsService from '@/services/ms_projects_service'

export const state = {
  loading: false,
  links: []
}

export const getters = {
  loading: (state) => state.loading,
  links: (state) => state.links
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_LINKS(state, payload) {
    state.links = payload
  }
}

export const actions = {
  async getLinks({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = (await ApiMsProjectsService.get(`/document_filter`, payload)).data
      commit('SET_LINKS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async addLink({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.post(`/post_document`, payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async deleteLink({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.delete(
        `/delete_document?document_id=${payload.document_id}&user=${payload.user}`
      )

      return resp
    } catch (error) {
      return error
    }
  }
}
