import ApiService from "@/state/api.service";

export const state = {
    subEtapasProyecto: [],
    subEtapaProyecto: [],
    resultadoCrearSubEtapaProyecto: {},
    errorSubEtapaProyecto: null,
};

export const getters = {
    subEtapasProyecto: (state) => state.subEtapasProyecto,
    subEtapaProyecto: (state) => state.subEtapaProyecto,
    resultadoCrearSubEtapaProyecto: (state) =>
        state.resultadoCrearSubEtapaProyecto,
    errorSubEtapaProyecto: (state) => state.errorSubEtapaProyecto,
};

export const mutations = {
    setSubEtapaProyectos(state, newValue) {
        state.subEtapasProyecto = newValue;
    },
    setSubEtapaProyecto(state, newValue) {
        state.subEtapaProyecto = newValue;
    },
    setResultadoCrearSubEtapaProyecto(state, newValue) {
        state.resultadoCrearSubEtapaProyecto = newValue;
    },
    setError(state, newValue) {
        state.errorSubEtapaProyecto = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerSubEtapasProyecto({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/subEtapaProyecto`)
                .then((response) => {
                    commit("setSubEtapaProyectos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSubEtapaProyecto({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/subEtapaProyecto`)
                .then((response) => {
                    commit("setEtapaProyecto", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearSubEtapaProyecto({ commit }, subEtapaProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/subEtapaProyecto`, subEtapaProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSubEtapaProyecto({ commit }, subEtapaProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/subEtapaProyecto`, subEtapaProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarSubEtapaProyecto({ commit }, subEtapaProyectoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/subEtapaProyecto/${subEtapaProyectoId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
