import ApiService from "@/state/api.service";

export const state = {
    actividadesProyectoPersonalObraHorasTrabajadas: [],
    actividadProyectoPersonalObraHorasTrabajadas: [],
    resultadoCrearActividadProyectoPersonalObraHorasTrabajadas: {},
    errorActividadProyectoPersonalObraHorasTrabajadas: null,
};

export const getters = {
    actividadesProyectoPersonalObraHorasTrabajadas: state => state.actividadesProyectoPersonalObraHorasTrabajadas,
    actividadProyectoPersonalObraHorasTrabajadas: state => state.actividadProyectoPersonalObraHorasTrabajadas,
    resultadoCrearActividadProyectoPersonalObraHorasTrabajadas: state => state.resultadoCrearActividadProyectoPersonalObraHorasTrabajadas,
    errorActividadProyectoPersonalObraHorasTrabajadas: state => state.errorActividadProyectoPersonalObraHorasTrabajadas,
};

export const mutations = {
    setActividadesProyectoPersonalObraHorasTrabajadas(state, newValue) {
        state.actividadesProyectoPersonalObraHorasTrabajadas = newValue
    },
    setActividadProyectoPersonalObraHorasTrabajadas(state, newValue) {
        state.actividadProyectoPersonalObraHorasTrabajadas = newValue
    },
    setResultadoCrearActividadProyectoPersonalObraHorasTrabajadas(state, newValue) {
        state.resultadoCrearActividadProyectoPersonalObraHorasTrabajadas = newValue
    },
    setError(state, newValue) {
        state.errorActividadProyectoPersonalObraHorasTrabajadas = newValue
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerPersonal({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/obtenerPersonal`).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObraHorasTrabajadas({ commit },actividadProyectoPersonalObraHorasTrabajadas) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObraHorasTrabajadas/${actividadProyectoPersonalObraHorasTrabajadas.codigoLugarInstalacion}/${actividadProyectoPersonalObraHorasTrabajadas.codigoProyecto}`).then((response) => {
                commit('setActividadesProyectoPersonalObraHorasTrabajadas', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObraHorasTrabajadasFecha({ commit },actividadProyectoPersonalObraHorasTrabajadas) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObraHorasTrabajadas/fecha/${actividadProyectoPersonalObraHorasTrabajadas.codigoLugarInstalacion}/${actividadProyectoPersonalObraHorasTrabajadas.codigoProyecto}/${actividadProyectoPersonalObraHorasTrabajadas.fecha}`).then((response) => {
                commit('setActividadesProyectoPersonalObraHorasTrabajadas', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObraHorasTrabajadasPersonalTodo({ commit },actividadProyectoPersonalObraHorasTrabajadas) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObraHorasTrabajadas/personal/todo/${actividadProyectoPersonalObraHorasTrabajadas.actividad_proyecto_avance_diario_id}/${actividadProyectoPersonalObraHorasTrabajadas.actividad_proyecto_id}/${actividadProyectoPersonalObraHorasTrabajadas.fecha}`).then((response) => {
                commit('setActividadesProyectoPersonalObraHorasTrabajadas', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObraHorasTrabajadasPersonal({ commit },actividadProyectoPersonalObraHorasTrabajadas) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObraHorasTrabajadas/personal/${actividadProyectoPersonalObraHorasTrabajadas.actividad_proyecto_avance_diario_id}/${actividadProyectoPersonalObraHorasTrabajadas.actividad_proyecto_id}/${actividadProyectoPersonalObraHorasTrabajadas.rut_personal_obra}`).then((response) => {
                commit('setActividadesProyectoPersonalObraHorasTrabajadas', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    obtenerActividadProyectoPersonalObraHorasTrabajadasId({ commit },actividadProyectoPersonalObraHorasTrabajadasId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/actividadProyectoPersonalObraHorasTrabajadas/id/${actividadProyectoPersonalObraHorasTrabajadasId}`).then((response) => {
                commit('setActividadProyectoPersonalObraHorasTrabajadas', response.data.body)
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    crearActividadProyectoPersonalObraHorasTrabajadas({commit},actividadProyectoPersonalObraHorasTrabajadas) {
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.post(`${urlApi}/actividadProyectoPersonalObraHorasTrabajadas`,actividadProyectoPersonalObraHorasTrabajadas).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                    reject(error);
                })
            });
         
    },
    actualizarActividadProyectoPersonalObraHorasTrabajadas({commit},actividadProyectoPersonalObraHorasTrabajadas) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/actividadProyectoPersonalObraHorasTrabajadas`,actividadProyectoPersonalObraHorasTrabajadas).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
    eliminarActividadProyectoPersonalObraHorasTrabajadas({commit},actividadProyectoPersonalObraHorasTrabajadasId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/actividadProyectoPersonalObraHorasTrabajadas/id/${actividadProyectoPersonalObraHorasTrabajadasId}`).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                if(error == 'ECONNABORTED' || error == 'Network Error')
                {
                    commit('setError', 'Sin Internet')
                }
                reject(error);
            })
        });
    },
};
