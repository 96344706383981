import ApiService from "@/state/api.service";

export const state = {
    actividadProyectos: [],
    actividadProyecto: [],
    resultadoCrearActividadProyecto: {},
    errorActividadProyecto: null,
};

export const getters = {
    actividadProyectos: (state) => state.actividadProyectos,
    actividadProyecto: (state) => state.actividadProyecto,
    resultadoCrearActividadProyecto: (state) =>
        state.resultadoCrearActividadProyecto,
    errorActividadProyecto: (state) => state.errorActividadProyecto,
};

export const mutations = {
    setActividadProyectos(state, newValue) {
        state.actividadProyectos = newValue;
    },
    setActividadProyecto(state, newValue) {
        state.actividadProyecto = newValue;
    },
    setResultadoCrearActividadProyecto(state, newValue) {
        state.resultadoCrearActividadProyecto = newValue;
    },
    setError(state, newValue) {
        state.errorActividadProyecto = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerActividadesProyecto({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyecto/${actividadProyecto.codigoLugarInstalacion}/${actividadProyecto.codigoProyecto}`
            )
                .then((response) => {
                    commit("setActividadProyectos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerActividadesProyectoTemplate({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyecto/template/${actividadProyecto.codigoLugarInstalacion}/${actividadProyecto.codigoProyecto}`
            )
                .then((response) => {
                    commit("setActividadProyectos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerActividadesProyectoFecha({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyecto/fecha/${actividadProyecto.codigoLugarInstalacion}/${actividadProyecto.codigoProyecto}/${actividadProyecto.fecha}`
            )
                .then((response) => {
                    commit("setActividadProyectos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerActividadProyectoId({ commit }, actividadProyectoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/actividadProyecto/id/${actividadProyectoId}`
            )
                .then((response) => {
                    commit("setActividadProyecto", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearActividadProyecto({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/actividadProyecto`, actividadProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearActividadProyectoGantt({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/actividadProyecto/gantt`,
                actividadProyecto
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearActividadProyectoTemplate({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/actividadProyecto/template`,
                actividadProyecto
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearActividadesProyectoBase({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/actividadProyecto/crearActividadesProyectoBase`,
                actividadProyecto
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarActividadProyecto({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/actividadProyecto`, actividadProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarActividadGanttProyecto({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/actividadProyecto/gantt`,
                actividadProyecto
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarActividadGanttProyectoTemplate({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/actividadProyecto/gantt/template`,
                actividadProyecto
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarActividadProyectoOrden({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/actividadProyecto/orden`,
                actividadProyecto
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarActividadProyecto({ commit }, actividadProyectoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/actividadProyecto/id/${actividadProyectoId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarGantt({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/actividadProyecto/eliminargantt/${actividadProyecto.codigoLugarInstalacion}/${actividadProyecto.codigoProyecto}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },




    crearActividadProyectoDependencia({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/actividadProyecto/dependencia`, actividadProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearActividadProyectoDependenciaTemplate({ commit }, actividadProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/actividadProyecto/template/dependencia`, actividadProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarActividadProyectoDependencia({ commit }, actividadProyectoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/actividadProyecto/dependencia/${actividadProyectoId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
