const ID_ROLE_KEY = "role";

export const getRole = () => {
  return window.localStorage.getItem(ID_ROLE_KEY);
};

export const saveRole = role => {
  window.localStorage.setItem(ID_ROLE_KEY, role);
};

export const destroyRole = () => {
  window.localStorage.removeItem(ID_ROLE_KEY);
};

export default { getRole, saveRole, destroyRole };