import { MATERIAL_REQUEST_RETURN_STATUS_LIST } from "@/constants/requestMaterial";
import { CODE_WAREHOUSE_CENTRAL_FLUXSOLAR } from "@/constants/requestMaterial";

export const getMappedReturnMaterial = (item) => {
    return {
      ...item,
      selectable: item.erp_status === MATERIAL_REQUEST_RETURN_STATUS_LIST.pending
    }
  }
  
export const getApproverFromEmail = (approvers, email) => {
  try {
    let existApprover = approvers.find(({approver}) => approver.email === email)
    return existApprover && existApprover.approver.substitute 
    ? {email: existApprover.approver.substitute.email, substitute: true, original: existApprover.approver.email} 
    : {email: existApprover?.approver?.email, substitute: false}    
  } catch (error) {
    console.log(error)
  }
}

export const getStockFromMaterial = (material, verifyCodeWarehouse = true) => {
  let materialStock = material.material_stocks.find((item) => {
    return verifyCodeWarehouse ? item.warehouse.code == CODE_WAREHOUSE_CENTRAL_FLUXSOLAR : item;
  });
  return materialStock ? materialStock.stock : 0;
}

export const getStockFromMaterialWarehouse = (material, warehouse_code) => {
  let materialStock = material.material_stocks.find((item) => {
    return item.warehouse.code == warehouse_code;
  });
  return materialStock ? materialStock.stock : 0;
}

export const getPriceFromMaterial = (material) => {
  return material.material_prices.length ? material.material_prices[0].price : 0
}