export const TYPE_CUBAGE_MAIN_TEAMS = 'Equipos Principales (Paneles e Inversores)'
export const TYPE_CUBAGE_MAIN_TEAMS_OTHERS = 'Equipos Principales (Otros)'
export const TYPE_ADITIONAL = 'Adicionales'

export const TYPES_EXECUTION_MATERIALS = {
  transport: 'Transporte de Personal',
  load_transport: 'Transporte de Carga',
  machinery: 'Maquinaria y Faena',
  labor: 'Mano de obra',
  lodging: 'Hospedaje y Viáticos',
  others: 'Otros'
}

export const DEFAULT_DISPATCH = "1";
export const DISPATCH_SUB_CONTRACT = "SubContrato"

export const tableHeadersPersonalTransport = [
  {
    text: "Nombre",
    align: "center",
    sortable: true,
    value: "name",
  },
  {
    text: "Valor diario",
    align: "center",
    sortable: true,
    value: "Unit_Cost",
  },
  {
    text: "Dias total",
    align: "center",
    sortable: true,
    value: "Days",
  },
  {
    text: "Cantidad",
    align: "center",
    sortable: true,
    value: "Quantity",
  },
  {
    text: "Valor total",
    align: "center",
    sortable: true,
    value: "total_cost",
  },
];

export const tableHeadersFreightTransport = [
  {
    text: "Nombre",
    align: "center",
    sortable: true,
    value: "name",
  },
  {
    text: "Valor",
    align: "center",
    sortable: true,
    value: "Unit_Cost",
  },
  {
    text: "Cantidad",
    align: "center",
    sortable: true,
    value: "Quantity",
  },
  {
    text: "Valor total",
    align: "center",
    sortable: true,
    value: "total_cost",
  },
];

export const tableHeadersMachineryAndWork = [
  {
    text: "Nombre",
    align: "center",
    sortable: true,
    value: "name",
  },
  {
    text: "Valor",
    align: "center",
    sortable: true,
    value: "Unit_Cost",
  },
  {
    text: "Cantidad",
    align: "center",
    sortable: true,
    value: "Quantity",
  },
  {
    text: "Hora",
    align: "center",
    sortable: true,
    value: "Hour",
  },
  {
    text: "Día / Mes",
    align: "center",
    sortable: true,
    value: "Day_Month",
  },
  {
    text: "Valor total",
    align: "center",
    sortable: true,
    value: "total_cost",
  },
];

export const tableHeadersLabor = [
  {
    text: "Nombre",
    align: "center",
    sortable: true,
    value: "name",
  },
  {
    text: "Valor",
    align: "center",
    sortable: true,
    value: "Unit_Cost",
  },
  {
    text: "Personal",
    align: "center",
    sortable: true,
    value: "Staff",
  },
  {
    text: "Día",
    align: "center",
    sortable: true,
    value: "Days",
  },
  {
    text: "Valor total",
    align: "center",
    sortable: true,
    value: "total_cost",
  },
]

export const tableHeadersLodgingAndPerDiem = [
  {
    text: "Nombre",
    align: "center",
    sortable: true,
    value: "name",
  },
  {
    text: "Valor",
    align: "center",
    sortable: true,
    value: "Unit_Cost",
  },
  {
    text: "Personal",
    align: "center",
    sortable: true,
    value: "Staff",
  },
  {
    text: "Día",
    align: "center",
    sortable: true,
    value: "Days",
  },
  {
    text: "Valor total",
    align: "center",
    sortable: true,
    value: "total_cost",
  },
];

export const tableHeadersOthers = [
  {
    text: "Nombre",
    align: "center",
    sortable: true,
    value: "name",
  },
  {
    text: "Valor",
    align: "center",
    sortable: true,
    value: "Unit_Cost",
  },
  {
    text: "Cantidad",
    align: "center",
    sortable: true,
    value: "Quantity",
  },
  {
    text: "Valor total",
    align: "center",
    sortable: true,
    value: "total_cost",
  },
]