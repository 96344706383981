import moment from 'moment'
export default {
    // Categorias de documentos
    document_category_quotes: 0,
    document_category_maverick_purchase: 1,
    document_category_other: 2,
    // Categorias de Texto de documentos
    document_category_quotes_text: "Cotizacion",
    document_category_maverick_purchase_text: "Maverick",
    document_category_other_text: "Otros",

    //Estatus de los servicios de una solicitud de servicio
    service_statuses: {
        in_quotation: "En Cotizacion",
        quotation_ready: "Cotizacion Lista",
        in_review: "En Revision",
        pending_validation: "Pendiente de Validacion",
        quotation_in_validation: "Maverick Validacion",
        purchase_validation: "Pendiente de Validacion por Abastecimiento",
        purchase_validation_variant: "Pendiente de Revision por Abastecimiento",
        user_validation: "Pendiente de Validacion por Usuario",
        user_validation_variant: "Pendiente de Revision por Usuario",
        pending: "Pendiente de Aprobacion",
        approved: "Aprobada",
        rejected: "Rechazada",
        rejected_variant: "Rechazado",
    },
    // Monto de servicio para solicitar mas documentos de cotizaciones
    service_amount_to_request_more_quotes_files: 5000000,

    //Monto Maximo de servicio para que solo sea cotizado por compras
    service_amount_to_request_only_quotes_from_supply: 100000000,

    // Numero de documentos para una solicitud de servicio que sobrepasa el monto de servicio
    min_number_of_documents_for_service_over_service_amount: 3,
    
    // Numero minimo de documentos para una solicitud de servicio
    min_number_of_documents_for_service: 1,

    //Código de cuenta de activos
    asset_account_code: 'AFI-000001',

    // Tipos de cambio
    coin_types: [
      {
        value: 'CLP',
        text: 'CLP',
        currency_symbol: '$'
      },
      {
        value: 'USD',
        text: 'USD',
        currency_symbol: '$'
      },
      {
        value: 'EUR',
        text: 'EUR',
        currency_symbol: '€'
      },
      {
        value: 'UF',
        text: 'UF',
        currency_symbol: 'UF'
      },
    ],

    getCurrencySymbol: function(coin) {
      return this.coin_types.find(item => item.value == coin).currency_symbol || '-';
    },
    
    //Obtener el listado de estatus de las solicitudes de servicio
    service_statuses_list: function() {
      return [
        this.service_statuses.in_quotation,
        this.service_statuses.pending,
        this.service_statuses.purchase_validation,
        this.service_statuses.purchase_validation_variant,
        this.service_statuses.user_validation,
        this.service_statuses.user_validation_variant,
        this.service_statuses.approved,
        this.service_statuses.rejected_variant,
      ]
    } ,
    getStatusCorrection: function(status) {
      return status
        .replace("Cotizacion", "Cotización")
        .replace("Aprobacion", "Aprobación")
        .replace("Revision", "Revisión")
        .replace("Validacion", "Validación");
    },
    getDocumentCategory: function(category, isMaverickRequest = false) {
      if(category == 'Otros' && !isMaverickRequest){
        return this.document_category_maverick_purchase
        
      }
      switch(category){
        case 'Cotizacion':
          return this.document_category_quotes
        case 'Maverick':
          return this.document_category_maverick_purchase
        default:
          return this.document_category_other 
      }
    },
    getTypeDocumentFromFile(document, isMaverickRequest = false){
      if(document.category == this.document_category_maverick_purchase && isMaverickRequest){
        return 'Maverick'
      }
      switch(document.category){
        case this.document_category_quotes:
          return 'Cotizacion'
        default:
          return 'Otros'
      }
    },
    // Verificar si el servicio es un activo
    isServiceIsAnAsset: function(service){
      return service.code == this.asset_account_code
    },
    //Calcular el monto total de servicio que es la cantidad por el monto de servicio
    calculateTotalService: function(service){
      let serviceAmount =  service.price ? Number(service.price.replaceAll(".", "")) : 0
      return serviceAmount * service.quantity;
    },
    // Validar si el servicio tiene una cotización con un precio y proveedor definido y una carta de aprobación maverick
    // && service.documents.find(document => document.category == this.document_category_maverick_purchase);
    ifServiceHasQuotedWithPriceAndSupplierAndHaveMaverickLetter: function (service){
      return service.documents.find(document => document.category == this.document_category_quotes && document.price && document.supplier_rut) 
    },
    // Obtener el color de la etapa de un servicio
    getStatusColor: function(status){
        switch(status){
          case this.service_statuses.in_quotation:
            return 'warning'
          case this.service_statuses.in_review:
            return 'cyan'  
          case this.service_statuses.quotation_ready:
            return 'success'
          case this.service_statuses.pending_validation:
            return 'info'
          case this.service_statuses.purchase_validation:
            return 'pink darken-2'
          case this.service_statuses.purchase_validation_variant:
            return 'deep-orange darken-2'
          case this.service_statuses.quotation_in_validation:
            return 'grey'
          case this.service_statuses.user_validation:
            return 'brown darken-2'
          case this.service_statuses.user_validation_variant:
            return 'teal darken-2'
          case this.service_statuses.pending:
            return 'purple'
          case this.service_statuses.rejected:
            return 'red darken-2'
          case this.service_statuses.rejected_variant:
            return 'red darken-2'
          default:
            return 'primary'
        }
      },
    // Validar si el servicio no tiene un comentario adjunto
    ifServiceHasNoComment: function (service){
      return service.comment == null || service.comment == '';
    },
    // Validar si el servicio posee una cotización adjunta y no tiene precio o proveedor definido
    ifServiceHasQuotedWithNoPriceOrSupplier: function (service){
      return service.documents.find(document => document.category == this.document_category_quotes && (!document.price || !document.supplier_rut));
    },
    // Validar que el servicio tenga al menos una cotización (Para compras)
    ifServiceHasQuotedFromTeamPurchase: function (service){
      return service.documents.find(
        document => document.document_type == this.document_category_quotes_text &&
        document.created_by !== service.created_by
      );
    },
    // Validar que todas las cotizaciones tengan un precio y proveedor definido (Para compras)
    ifServiceHasQuotedWithNoPriceOrSupplierFromTeamPurchase: function (service){
      return service.documents.find(
        document => document.document_type == this.document_category_quotes_text &&
        (!document.price || !document.supplier) &&
        document.created_by !== service.created_by
      );
    },
    // Obtener las cotizaciones del servicio
    getServiceQuotes: function(service){
      return service.documents.filter(document => document.category == this.document_category_quotes)
    },
    // Obtener las cotizaciones del servicio posterior a la creacion del servicio
    getServiceQuotesPostService: function(service){
      return service.documents.filter(document => document.document_type == this.document_category_quotes_text)
    },
    // Validar si hay mas de una cotizacion y no hay ninguna seleccionada
    ifServiceHasMoreThanOneQuoteAndNoOneIsSelected: function(service){
      return this.getServiceQuotes(service).length > 1 && service.documents.filter(document => document.category == this.document_category_quotes && document.selected).length == 0
    },  
    // Validar si el documento es una cotización
    isDocumentAQuote: function(document){
      return document.category == this.document_category_quotes
    },
    // Validar si el servicio necesita una cotización
    getIfserviceNeedQuotation: function(service){
      if(
        this.getServiceQuotes(service).length < this.min_number_of_documents_for_service_over_service_amount
        && service.documents.some(document => document.price && Number(document.price.replaceAll(".", "").replaceAll(",", ".")) >= this.service_amount_to_request_more_quotes_files)
      ){
        return true
      }
      if(!this.getServiceQuotes(service).length){
        return true
      }

      return false
    },
    // Validar si la solicitud tiene 14 dias de diferecia o mas entre la fecha de cancelacion y la fecha actual
    isRequestCancelationHas14DaysDiffFromToday: function(request){
      let requestDate = moment(request.cancellation_date).format('YYYY-MM-DD')
      let todayDate = moment()
      return todayDate.diff(requestDate, 'days') >= 14
    },
    

}