import ApiService from "@/state/api.service";

export const state = {
    tipoProyectos: [],
    tipoProyecto: [],
    resultadoCrearTipoProyecto: {},
    errorTipoProyecto: null,
};

export const getters = {
    tipoProyectos: (state) => state.tipoProyectos,
    tipoProyecto: (state) => state.tipoProyecto,
    resultadoCrearTipoProyecto: (state) => state.resultadoCrearTipoProyecto,
    errorTipoProyecto: (state) => state.errorTipoProyecto,
};

export const mutations = {
    setTipoProyectos(state, newValue) {
        state.tipoProyectos = newValue;
    },
    setTipoProyecto(state, newValue) {
        state.tipoProyecto = newValue;
    },
    setResultadoCrearTipoProyecto(state, newValue) {
        state.resultadoCrearTipoProyecto = newValue;
    },
    setError(state, newValue) {
        state.errorTipoProyecto = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerTipoProyectos({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/tipoProyecto`)
                .then((response) => {
                    commit("setTipoProyectos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearTipoProyecto({ commit }, tipoProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/tipoProyecto`, tipoProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarTipoProyecto({ commit }, tipoProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/tipoProyecto`, tipoProyecto)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarTipoProyecto({ commit }, tipoProyectoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/tipoProyecto/${tipoProyectoId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
