import ApiService from "@/state/api.service";
export const state = {
    tipoVentas: [],
    tipoVenta: [],
    resultadoCrearTipoVenta: {},
    errorTipoVenta: null,
};

export const getters = {
    tipoVentas: (state) => state.tipoVentas,
    tipoVenta: (state) => state.tipoVenta,
    resultadoCrearTipoVenta: (state) => state.resultadoCrearTipoVenta,
    errorTipoVenta: (state) => state.errorTipoVenta,
};

export const mutations = {
    setTipoVentas(state, newValue) {
        state.tipoVentas = newValue;
    },
    setTipoVenta(state, newValue) {
        state.tipoVenta = newValue;
    },
    setResultadoCrearTipoVenta(state, newValue) {
        state.resultadoCrearTipoVenta = newValue;
    },
    setError(state, newValue) {
        state.errorTipoVenta = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerTipoVentas({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/tipoVenta`)
                .then((response) => {
                    commit("setTipoVentas", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearTipoVenta({ commit }, tipoVenta) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/tipoVenta`, tipoVenta)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarTipoVenta({ commit }, tipoVenta) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/tipoVenta`, tipoVenta)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarTipoVenta({ commit }, tipoVentaId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/tipoVenta/${tipoVentaId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
