import BaseService from "../../services/base_service";
import ApiMsApproveRequestMaterialsService from "@/services/ms_approve_request_materials";

const requestMaterialsService = new BaseService(
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_URL,
  process.env.VUE_APP_MS_REQUESTS_MATERIALS_API_KEY
);

export const state = {
  loading: false,
  materialsRequestsToApprove: {
    total_items: 0,
    total_pages: 1,
    items: [],
  },
  businessUnits: [],
};

export const getters = {
  loading: (state) => state.loading,
  materialsRequestsToApprove: (state) => state.materialsRequestsToApprove,
  businessUnits: (state) => state.businessUnits,
};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_MATERIALS_REQUESTS_TO_APPROVE(state, payload) {
    state.materialsRequestsToApprove = payload;
    state.materialsRequestsToApprove.items = payload?.items
      ? payload.items.map((item) => {
          const { material_request_json, ...rest } = item;
          return {
            ...material_request_json,
            ...rest,
            request_type_original: material_request_json.request_type,
            materials: Object.keys(material_request_json.materials).map(
              (key) => {
                return {
                  code: key,
                  quantity: material_request_json.materials[key],
                  description: null,
                  price: 0,
                };
              }
            ),
            default_request_material_json: material_request_json,
          };
        })
      : [];
  },
  UPDATE_VALUE_REQUEST_APPROVAL(state, payload) {
    let requestToApprove = state.materialsRequestsToApprove.items.find(
      (item) => item.id === payload.id
    );
    requestToApprove[payload.field] = payload.value;
  },
  UPDATE_MATERIAL_REQUEST_APPROVAL(state, payload) {
    let requestToApprove = state.materialsRequestsToApprove.items.find(
      (item) => item.id === payload.id
    );
    requestToApprove.materials.forEach((element) => {
      if (element.code == payload.codeProduct) {
        element[payload.field] = payload.value;
      }
    });
  },
  UPDATE_MATERIALS_REQUEST_APPROVAL(state, payload) {
    let requestToApprove = state.materialsRequestsToApprove.items.find(
      (item) => item.id === payload.id
    );
    requestToApprove.materials = [
      ...requestToApprove.materials,
      ...payload.materials,
    ];
  },
  UPDATE_DATA_MATERIALS_CURRENT_REQUEST_APPROVE(state, payload) {
    let requestToApprove = state.materialsRequestsToApprove.items.find(
      (item) => item.id === payload.id
    );
    if (payload.is_service) {
      requestToApprove.materials = requestToApprove.service_materials.map(
        (item) => {
          return {
            code: item.sap_code,
            quantity: requestToApprove.default_request_material_json.materials.find(
              (material) => {
                return material[item.sap_code];
              }
            )[item.sap_code],
            price: item.unit_price,
            description: item.name,
            code: item.sap_code,
            documents: item.documents,
            comments: item.comments,
          };
        }
      );
    } else {
      requestToApprove.materials = requestToApprove.materials.map((item) => {
        let material = payload.materials.find(
          (material) => material.code == item.code
        );
        return {
          ...item,
          price: material.material_prices.length
            ? material.material_prices[material.material_prices.length - 1]
                .price
            : 0,
          description: material.name,
          option: material.material_prices[material.material_prices.length - 1].option,
        };
      });
    }
  },
  SPLICE_MATERIAL_REQUEST_APPROVAL(state, payload) {
    let requestToApprove = state.materialsRequestsToApprove.items.find(
      (item) => item.id === payload.id
    );
    requestToApprove.materials = requestToApprove.materials.filter(
      (element) => {
        return element.code !== payload.codeProduct;
      }
    );
  },
  SET_BUSINESS_UNITS(state, payload) {
    state.businessUnits = payload;
  },
};
export const actions = {
  async getBusinessUnits({ commit }, payload = {}) {
    try {
      const resp = (
        await requestMaterialsService.get(
          `/api/maintenance/all_business_units`,
          payload
        )
      ).data;
      commit("SET_BUSINESS_UNITS", resp);
      return resp;
    } catch (error) {
      return error;
    }
  },
  async getMaterialsRequestsToApprove({ commit }, payload) {
    try {
      const resp = (
        await ApiMsApproveRequestMaterialsService.get(
          `/api/get_approvals`,
          payload
        )
      ).data;
      commit("SET_MATERIALS_REQUESTS_TO_APPROVE", resp);
      return resp;
    } catch (error) {
      return error;
    }
  },
  async updateMaterialRequestApprove({ commit }, { params, payload }) {
    commit("SET_LOADING", true);
    try {
      const resp = await ApiMsApproveRequestMaterialsService.put(
        `/api/update_material_request?user=${params.user}&request_id=${params.request_id}&monto_nuevo=${params.monto_nuevo}`,
        payload
      );
      return resp;
    } catch (error) {
      return error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async approveOrRejectRequestApprove({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const resp = await ApiMsApproveRequestMaterialsService.post(
        `/api/approve_material_request`,
        payload
      );
      return resp;
    } catch (error) {
      return error.response;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async approveOrRejectRequestApproveRestricted({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsApproveRequestMaterialsService.post(
        `/api/restrictions/approve_restriction_request_material`,
        payload
      )
      return resp
    } catch (error) {
      return error.response
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async cancelRequestApprove({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const resp = await ApiMsApproveRequestMaterialsService.put(
        `/api/cancel_material_request?user=${payload.user}&request_id=${payload.request_id}`
      );
      return resp;
    } catch (error) {
      return error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  cleanRequestsMaterialsToApprove({ commit }) {
    commit("SET_MATERIALS_REQUESTS_TO_APPROVE", []);
  },
  updateValueRequestApproval({ commit }, payload) {
    commit("UPDATE_VALUE_REQUEST_APPROVAL", payload);
  },
  updateMaterialRequestApproval({ commit }, payload) {
    commit("UPDATE_MATERIAL_REQUEST_APPROVAL", payload);
  },
  updateMaterialsRequestApproval({ commit }, payload) {
    commit("UPDATE_MATERIALS_REQUEST_APPROVAL", payload);
  },
  spliceMaterialRequestAproval({ commit }, payload) {
    commit("SPLICE_MATERIAL_REQUEST_APPROVAL", payload);
  },
  updateDataMaterialsCurrentRequestApprove({ commit }, payload) {
    commit("UPDATE_DATA_MATERIALS_CURRENT_REQUEST_APPROVE", payload);
  },
  async createRequestApproveMaterials({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const resp = await ApiMsApproveRequestMaterialsService.post(
        `/api/material_request_approval`,
        payload
      );
      return resp;
    } catch (error) {
      return error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
};
