import ApiService from "@/state/api.service";

export const state = {
    appFlexEnergeticas: [],
    appFlexEnergetica: [],
    resultadoCrearAppFlexEnergetica: {},
    errorAppFlexEnergetica: null,
};

export const getters = {
    appFlexEnergeticas: (state) => state.appFlexEnergeticas,
    appFlexEnergetica: (state) => state.appFlexEnergetica,
    resultadoCrearAppFlexEnergetica: (state) =>
        state.resultadoCrearAppFlexEnergetica,
    errorAppFlexEnergetica: (state) => state.errorAppFlexEnergetica,
};

export const mutations = {
    setAppFlexEnerAeticas(state, newValue) {
        state.appFlexEnergeticas = newValue;
    },
    setAppFlexEnerAetica(state, newValue) {
        state.appFlexEnergetica = newValue;
    },
    setResultadoCrAarappFlexEnergetica(state, newValue) {
        state.resultadoCrearAppFlexEnergetica = newValue;
    },
    setError(state, newValue) {
        state.errorAppFlexEnergetica = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerAppFlexEnergeticas({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/appFlexEnergetica`)
                .then((response) => {
                    commit("setAppFlexEnerAeticas", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerAppFlexEnergetica({ commit }, nombre) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/appFlexEnergetica/${nombre}`)
                .then((response) => {
                    commit("setAppFlexEnerAetica", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearAppFlexEnergetica({ commit }, appFlexEnergetica) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/appFlexEnergetica`, appFlexEnergetica)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarAppFlexEnergetica({ commit }, appFlexEnergetica) {
        // commit('setResultadoCrAarappFlexEnergetica', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/appFlexEnergetica`, appFlexEnergetica)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarAppFlexEnergetica({ commit }, appFlexEnergeticaId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/appFlexEnergetica/eliminar/${appFlexEnergeticaId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
