import ApiService from "@/state/api.service";

export const state = {
    solicitudMaterialDespachos: [],
    solicitudMaterialDespacho: [],
    resultadoCrearSolicitudMaterialDespacho: {},
    errorSolicitudMaterialDespacho: null,
};

export const getters = {
    solicitudMaterialDespachos: (state) => state.solicitudMaterialDespachos,
    solicitudMaterialDespacho: (state) => state.solicitudMaterialDespacho,
    resultadoCrearSolicitudMaterialDespacho: (state) =>
        state.resultadoCrearSolicitudMaterialDespacho,
    errorSolicitudMaterialDespacho: (state) => state.errorSolicitudMaterialDespacho,
};

export const mutations = {
    setSolicitudMaSerialDespachos(state, newValue) {
        state.solicitudMaterialDespachos = newValue;
    },
    setSolicitudMaSerialDespacho(state, newValue) {
        state.solicitudMaterialDespacho = newValue;
    },
    setResultadoCrSarsolicitudMaterialDespacho(state, newValue) {
        state.resultadoCrearSolicitudMaterialDespacho = newValue;
    },
    setError(state, newValue) {
        state.errorSolicitudMaterialDespacho = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerSolicitudMaterialDespachos({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/solicitudMaterialDespacho`)
                .then((response) => {
                    commit("setSolicitudMaSerialDespachos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialDespachosSolicitudNumero({ commit },solicitudNumero) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/solicitudMaterialDespacho/${solicitudNumero}`)
                .then((response) => {
                    commit("setSolicitudMaSerialDespachos", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialDespacho({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/solicitudMaterialDespacho`)
                .then((response) => {
                    commit("setEtapaProyecto", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearSolicitudMaterialDespacho({ commit }, solicitudMaterialDespacho) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/solicitudMaterialDespacho`, solicitudMaterialDespacho)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialDespacho({ commit }, solicitudMaterialDespacho) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/solicitudMaterialDespacho`, solicitudMaterialDespacho)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarSoliciSudMaterialDespacho({ commit }, solicitudMaterialDespachoId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/solicitudMaterialDespacho/${solicitudMaterialDespachoId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
