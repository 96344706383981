import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import store from "@/state/store";
import routes from "./routes";
import { userService } from "@/helpers/authservice/user.service";

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "page",
});

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
    const publicPages = ["/login", "/register", "/forgot-password"];
    const authpage = !publicPages.includes(routeTo.path);
    // const loggeduser = localStorage.getItem("user");
    let loggeduser = store.getters["auth/userAuthInfo"];
    const authRequired = routeTo.meta.authRequired;
    
    if(!loggeduser){
      await store.dispatch("auth/verifySessionUser");
      loggeduser = store.getters["auth/userAuthInfo"];
    }
    
    if (authRequired) {
      if (!loggeduser) {
        store.dispatch("auth/logout");
        return next("/login");
      }

      let usuario = loggeduser;
      let rolModuloPermisos = usuario.rol_modulo_permisos;
      let nombreModulo = routeTo.name;

      let permiso = rolModuloPermisos.find(
        (c) =>
          c.codigo_rol == usuario.codigo_rol &&
          c.nombre_modulo_permiso == nombreModulo
      );

      if (permiso == null) {
        store.dispatch("auth/logout");
        return next("/login");
      }
      if (!permiso.lectura) {
        store.dispatch("auth/logout");
        return next("/login");
      }
    }

    if (authpage && !loggeduser) {
      return next("/login");
    }

    if(loggeduser){
      //Acciones para guardar logs de acciones de usuarios
      await store.dispatch("auth/saveLogActionUser", {
        user: loggeduser.email,
        url: window.location.host + routeTo.path
      });
    }

    //Acciones para verificar mantenimiento de la plataforma
    await store.dispatch("auth/maintenance");
    let maintenance = store.getters["auth/maintenanceStatus"];
    let emails = store.getters["auth/usersInWhiteList"];

    if (!emails.length) {
      await store.dispatch("auth/getUsersInWhiteList");
      emails = store.getters["auth/usersInWhiteList"];
    }
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null) {
      const emailExistsInWhitelist = emails.map(userWhitelist => userWhitelist.email).includes(user.email);
      if (routeTo.path !== "/maintenance") {
        return maintenance.mode && !emailExistsInWhitelist
          ? next("/maintenance")
          : next();
      } else {
        if (!maintenance.mode) {
          next("/");
        } else {
          next();
        }
      }
    } else {
      next()
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

export default router;
