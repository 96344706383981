import ApiMsMaterialsService from '@/services/ms_materials_service'
import ApiMsErpService from '@/services/ms_erp_service'
import {
  getMappedDocumentMaterial,
  getMappedMaterialExecution,
  getMappedCubageOtherAttachment,
  getMappedCubageBoardPanels
} from '@/helpers/cubage'

export const state = {
  loading: false,
  loadingSyncMaterials: false,
  cubages: [],
  cubagesWithMaterials: [],
  currentCubage: null,
  cubageMaterials: [],
  cubageBoardAttachments: [],
  cubageBoardPanels: [],
  cubageOthersAttachments: [],
  initialFillRequests: [],
  allCubageMaterials: null,
  allPanels: [],
  allUnits: [],
  paginatedMaterials: {},
  typesMaterial: [],
  dispatchs: [],
  cubageMaterialsExecution: [],
}

export const getters = {
  loading: (state) => state.loading,
  loadingSyncMaterials: (state) => state.loadingSyncMaterials,
  cubages: (state) => state.cubages,
  cubagesWithMaterials: (state) => state.cubagesWithMaterials,
  cubageBoardAttachments: (state) => state.cubageBoardAttachments,
  cubageBoardPanels: (state) => 
    state.cubageBoardPanels.map(item => getMappedCubageBoardPanels(item)),
  cubageOthersAttachments: (state) =>
    state.cubageOthersAttachments.map((item) => getMappedCubageOtherAttachment(item)),
  currentCubage: (state) => state.currentCubage,
  initialFillRequests: (state) => state.initialFillRequests,
  cubageMaterials: (state) =>
    state.cubageMaterials.map((item) => getMappedDocumentMaterial(item)),
  allCubageMaterials: (state) => state.allCubageMaterials,
  allPanels: (state) => state.allPanels,
  allUnits: (state) => state.allUnits,
  paginatedMaterials: (state) => state.paginatedMaterials,
  typesMaterial: (state) => state.typesMaterial,
  dispatchs: (state) => state.dispatchs,
  cubageMaterialsExecution: (state) =>
    state.cubageMaterialsExecution.map((item) => getMappedMaterialExecution(item))
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_LOADING_SYNC_MATERIALS(state, payload){
    state.loadingSyncMaterials = payload
  },
  SET_DEFAULT_CURRENT_CUBAGE(state) {
    state.currentCubage = null
  },
  SET_DEFAULT_CUBAGE_MATERIALS(state) {
    state.cubageMaterials = []
  },
  SET_CUBAGES(state, payload) {
    state.cubages = payload
  },
  SET_CUBAGES_WITH_MATERIALS(state, payload) {
    state.cubagesWithMaterials = payload
  },
  SET_CUBAGE_BOARD_ATTACHMENTS(state, payload) {
    state.cubageBoardAttachments = payload
  },
  SET_CUBAGE_BOARD_PANELS(state, payload) {
    state.cubageBoardPanels = payload
  },
  SET_CUBAGE_OTHERS_ATTACHMENTS(state, payload) {
    state.cubageOthersAttachments = payload
  },
  SET_CURRENT_CUBAGE(state, payload) {
    state.currentCubage = { ...payload, isClosed: false }
  },
  SET_INITIAL_FILL_REQUESTS(state, payload) {
    state.initialFillRequests = payload
  },
  UPDATE_STATUS_CLOSED_CURRENT_CUBAGE(state) {
    state.currentCubage.isClosed = true
  },
  SET_CUBAGE_MATERIALS(state, payload) {
    state.cubageMaterials = payload
    state.currentCubage.isClosed = state.cubageMaterials.some(
      (material) => material.erp_status !== null
    )
  },
  SET_TYPES_MATERIAL(state, payload) {
    state.typesMaterial = payload
  },
  SET_DISPATCHS(state, payload) {
    state.dispatchs = payload
  },
  SET_MATERIALS(state, payload) {
    state.paginatedMaterials = payload
    state.paginatedMaterials.pages = payload.pages ? payload.pages : payload.materialpages
    state.paginatedMaterials.materials = state.paginatedMaterials.materials.map((material) => {
      return {
        ...material,
        stock: material.stocks.length
          ? material.stocks[material.stocks.length - 1].quantity
          : 0,
        cost: material.costs.length ? material.costs[material.costs.length - 1].amount : 0
      }
    })
  },
  UPDATE_CUBAGE_DOCUMENT_MATERIAL(state, payload) {
    state.cubageMaterials.forEach((item) => {
      if (item.id === payload.id) {
        item.id = payload.newId
        item.cost = payload.cost
        ;(item.quantity = payload.quantity),
          (item.dispatch = {
            description: payload.dispatch
          })
      }
    })
  },
  UPDATE_QUANTITY_CUBAGE_BOARD_PANEL(state, payload) {
    state.cubageBoardPanels.forEach((item) => {
      if (item.id === payload.doc_panel_id) {
        item.id = payload.newId
        item.quantity = payload.quantity
      }
    })
  },
  SET_ALL_CUBAGE_MATERIALS(state, payload) {
    state.allCubageMaterials = payload
  },
  SET_ALL_PANELS(state, payload) {
    state.allPanels = payload
  },
  SET_ALL_UNITS(state, payload) {
    state.allUnits = payload
  },
  SET_CUBAGE_MATERIALS_EXECUTION(state, payload) {
    state.cubageMaterialsExecution = payload.filter((item) => item.name)
  },
  ADD_CUBAGE_MATERIAL_EXECUTION(state, payload) {
    state.cubageMaterialsExecution.push(payload)
  },
  SPLICE_CUBAGE_MATERIAL_EXECUTION_PRE_CREATED(state, payload) {
    state.cubageMaterialsExecution = state.cubageMaterialsExecution.filter(
      (material) => material.id !== payload
    )
  },
  SPLICE_DOCUMENT_MATERIAL(state, payload) {
    state.cubageMaterials = state.cubageMaterials.filter((material) => material.id !== payload)
  },
  SPLICE_MULTIPLE_DOCUMENT_MATERIAL(state, payload) {
    state.cubageMaterials = state.cubageMaterials.filter(
      (material) => !payload.some((item) => material.id === item)
    )
  }
}

export const actions = {
  async getAllCubagesMaterials({ commit }, payload = {}) {
    commit('SET_LOADING', true)
    try {
      const resp = (await ApiMsMaterialsService.get(`/filter_doc_materials`, payload)).data
      commit('SET_ALL_CUBAGE_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getAllCubages({ commit }) {
    try {
      const resp = (await ApiMsMaterialsService.get(`/all_docs`)).data
      commit('SET_CUBAGES', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getCubagesWithMaterials({ commit }) {
    try {
      const resp = (await ApiMsMaterialsService.get(`/valid_documents`)).data
      commit('SET_CUBAGES_WITH_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getCubageFromPlaceId({ commit }, payload) {
    let url = payload.is_sap ? 
      `/post_document?place_id=${payload.place_id}&user_id=${payload.user_id}&is_sap=true` 
      : `/post_document?place_id=${payload.place_id}&user_id=${payload.user_id}`
    try {
      const resp = (
        await ApiMsMaterialsService.post(
          url,
        )
      ).data
      commit('SET_CURRENT_CUBAGE', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getCubageMaterials({ commit }, payload) {
    commit('SET_CUBAGE_MATERIALS', [])
    try {
      const resp = (
        await ApiMsMaterialsService.get(`/get_document_materials/${payload.document_id}`)
      ).data
      commit('SET_CUBAGE_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllMaterials({ commit, getters }, payload = {}) {
    commit('SET_LOADING', true)
    try {
      const resp = (await ApiMsMaterialsService.get('/filter_materials', payload)).data
      commit('SET_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async syncAllMaterials({commit}){
    try {
      commit('SET_LOADING_SYNC_MATERIALS', true)
      const resp = await ApiMsMaterialsService.post(
        `/update_materials_sap`
      )
      return resp
    } catch (error) {
      return error.resp
    } finally {
      commit('SET_LOADING_SYNC_MATERIALS', false)
    }
  },
  async getAllMaterialsFromWarehouse({ commit, getters }, payload = {}) {
    commit('SET_LOADING', true)
    try {
      const resp = (await ApiMsErpService.get('/filter_materials_warehouse', payload)).data
      commit('SET_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getInitialFillRequestsFromPlace({ commit }, payload) {
    try {
      const resp = (
        await ApiMsMaterialsService.get(
          `/solicitudes_erp/${payload.place_id}?place_id=${payload.place_id}`
        )
      ).data
      commit('SET_INITIAL_FILL_REQUESTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllPanels({ commit, getters }) {
    try {
      const resp = (await ApiMsMaterialsService.get('/get_all_panels')).data
      commit('SET_ALL_PANELS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllUnits({ commit, getters }) {
    try {
      const resp = (await ApiMsMaterialsService.get('/measurements_db')).data
      commit('SET_ALL_UNITS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllTypesMaterial({ commit }) {
    try {
      const resp = (await ApiMsMaterialsService.get('/all_types')).data
      commit('SET_TYPES_MATERIAL', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllDispatchs({ commit }) {
    try {
      const resp = (await ApiMsMaterialsService.get('/all_dispatch')).data
      commit('SET_DISPATCHS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async addMaterialsToCubage({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post('/post_document_materials', payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async replaceMaterialWithoutCode({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post('/substitute_custom', payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async cloneCubageFromOtherCubage({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post('/clone_document_materials', payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async uploadDocumentMaterialsFromExcel({ commit }, payload) {
    let formData = new FormData()
    formData.append('file', payload.file)
    try {
      const resp = await ApiMsMaterialsService.post(
        `/upload_excel_cubicacion?codigo_proyecto=${payload.codigo_proyecto}&user=${payload.user}&tipo_solicitud=${payload.tipo_solicitud}`,
        formData
      )
      return resp
    } catch (error) {
      return error.response
    }
  },
  async uploadMaterialsExcelFromCubage({commit}, payload){
    let formData = new FormData()
    formData.append('file', payload.file)
    try {
      const resp = await ApiMsMaterialsService.post(
        `/upload_cubicacion_v2?codigo_proyecto=${payload.codigo_proyecto}&user=${payload.user}`,
        formData
      )
      return resp
    } catch (error) {
      return error.response
    }
  },
  async getCubageBoardPanels({ commit }, payload) {
    try {
      const resp = (
        await ApiMsMaterialsService.get(`/get_document_panels/${payload.document_id}`)
      ).data
      commit('SET_CUBAGE_BOARD_PANELS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getCubageBoardAttachments({ commit }, payload) {
    try {
      const resp = (
        await ApiMsMaterialsService.get(
          `/document_attachments?document_id=${payload.document_id}`
        )
      ).data
      commit('SET_CUBAGE_BOARD_ATTACHMENTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getCubageOthersAttachments({ commit }, payload) {
    try {
      const resp = (
        await ApiMsMaterialsService.get(`/document_extras?document_id=${payload.document_id}`)
      ).data
      commit('SET_CUBAGE_OTHERS_ATTACHMENTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async uploadBoardAttachmentFromDocument({ commit }, payload) {
    let formData = new FormData()
    formData.append('file', payload.file)
    formData.append('info', payload.info)
    try {
      const resp = await ApiMsMaterialsService.post(`/upload_aws`, formData)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async addCubageBoardPanelFromDocument({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post(`/document_panels`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateCubageBoardPanel({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post(`/update_document_panels`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateCubageBoardPanelFromDocument({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post(`/document_panels`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateDocumentMaterial({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post('/update_document_material', payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async addMaterialCustom({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post('/custom_material', payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async addCommentToDocumentMaterial({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post('/post_comment', payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async addCommentToModel({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post('/post_comment_v2', payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async downloadExcelFromCubage({ commit }, payload) {
    try {
      const resp = (
        await ApiMsMaterialsService.get(`/xlsx/${payload.document_id}`, {}, 'blob')
      ).data
      return resp
    } catch (error) {
      return error
    }
  },
  async downloadBaseExcelForMassiveUploadMaterials({ commit }, payload = {}) {
    try {
      const resp = (
        await ApiMsMaterialsService.get(`excel_model`, payload, 'blob')
      ).data
      return resp
    } catch (error) {
      return error
    }
  },
  async deleteDocumentMaterial({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.delete(
        `/delete_doc_material/${payload.document_material_id}?user_id=${payload.user_id}`
      )
      commit('SPLICE_DOCUMENT_MATERIAL', payload.document_material_id)
      return resp
    } catch (error) {
      return error
    }
  },
  async deleteCubageBoardPanel({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.delete(
        `/delete_document_panels?id=${payload.id}&user=${payload.user}`
      )
      return resp
    } catch (error) {
      return error
    }
  },
  async deleteAttachment({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.delete(`/delete_aws`, payload)
      return resp
    } catch (error) {
      return error
    }
  },
  async closeCubage({ commit }, payload) {
    let url =  `/post_document_to_ERP?document_id=${payload.document_id}&user=${payload.user}`
    
    url = payload.sap_stage ? `${url}&sap_stage=${payload.sap_stage}` : url 

    try {
      const resp = await ApiMsMaterialsService.post(
        url,
        payload
      )
      if (resp.status === 200) {
        commit('UPDATE_STATUS_CLOSED_CURRENT_CUBAGE')
      }
      return resp
    } catch (error) {
      return error.response
    }
  },

  async deleteMultipleDocumentMaterial({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.delete(`/batch_delete_doc_material`, payload)
      commit('SPLICE_MULTIPLE_DOCUMENT_MATERIAL', payload.material_list)
      return resp
    } catch (error) {
      return error
    }
  },
  async getCubageMaterialsExecution({ commit }, payload) {
    commit('SET_CUBAGE_MATERIALS_EXECUTION', [])
    try {
      const resp = (
        await ApiMsMaterialsService.get(`/get_document_services/${payload.document_id}`)
      ).data
      commit('SET_CUBAGE_MATERIALS_EXECUTION', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async addCubageMaterialExecution({ commit }, { payload, oldId }) {
    try {
      const resp = await ApiMsMaterialsService.post('/document_services', payload)
      commit('SPLICE_CUBAGE_MATERIAL_EXECUTION_PRE_CREATED', oldId)
      commit('ADD_CUBAGE_MATERIAL_EXECUTION', resp.data)
      return resp
    } catch (error) {
      return error
    }
  },
  async deleteMultipleCubageMaterialesExecution({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.delete(`/delete_services`, payload)
      return resp
    } catch (error) {
      return error
    }
  },
  updateValuesCubageDocumentMaterial({ commit }, payload) {
    commit('UPDATE_CUBAGE_DOCUMENT_MATERIAL', payload)
    return true
  },
  updateQuantityCubageBoardPanel({ commit }, payload) {
    commit('UPDATE_QUANTITY_CUBAGE_BOARD_PANEL', payload)
    return true
  },
  addCubageMaterialExecutionPreCreated({ commit }, payload) {
    commit('ADD_CUBAGE_MATERIAL_EXECUTION', payload)
    return true
  },
  spliceCubageMaterialExecutionPreCreated({ commit }, payload) {
    commit('SPLICE_CUBAGE_MATERIAL_EXECUTION_PRE_CREATED', payload)
    return true
  },
  setDefaultCurrentCubage({ commit }) {
    commit('SET_DEFAULT_CURRENT_CUBAGE')
  },
  setDefaultCubageMaterials({ commit }) {
    commit('SET_DEFAULT_CUBAGE_MATERIALS')
  },
  cleanInitialFillRequests({commit}){
    commit('SET_INITIAL_FILL_REQUESTS', [])
  }
}
