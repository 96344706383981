import ApiMsReturnMaterialsService from '@/services/ms_return_materials_service'

export const state = {
  loading: false,
  loadingRequests: false,
  loadingMaterialsRequest: false,
  requestsConsumptionMaterials: [],
  requestMaterials: [],
}

export const getters = {
  loading: (state) => state.loading,
  loadingRequests: (state) => state.loadingRequests,
  loadingMaterialsRequest: (state) => state.loadingMaterialsRequest,
  requestsConsumptionMaterials: (state) => state.requestsConsumptionMaterials,
  requestMaterials: (state) => state.requestMaterials,
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_LOADING_REQUESTS(state, payload) {
    state.loadingRequests = payload
  },
  SET_LOADING_MATERIALS_REQUEST(state, payload) {
    state.loadingMaterialsRequest = payload
  },
  SET_REQUESTS_CONSUMPTION_MATERIALS(state, payload){
    state.requestsConsumptionMaterials = payload
  },
  SET_REQUEST_MATERIALS(state, payload){
    state.requestMaterials = payload
  },
}

export const actions = {
  async getMaterialsFromRequestConsumption({ commit }, payload = {}) {
    commit('SET_LOADING_MATERIALS_REQUEST', true)
    try {
      const resp = (await ApiMsReturnMaterialsService.get(`/api/consumed/filter_consumed_materials`, payload)).data
      commit('SET_REQUEST_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING_MATERIALS_REQUEST', false)
    }
  },
  async getRequestsConsumptionMaterialsFromProject({ commit }, payload = {}) {
    commit('SET_LOADING_REQUESTS', true)
    try {
      const resp = (await ApiMsReturnMaterialsService.get(`/api/consumed/filter_consume_request`, payload)).data
      commit('SET_REQUESTS_CONSUMPTION_MATERIALS', resp)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING_REQUESTS', false)
    }
  },
  async sendRequestConsumptionMaterial({commit}, payload){
    try {
      const resp = await ApiMsReturnMaterialsService.post(`/api/consumed/post_consumed_materials`, payload)
      return resp
    } catch (error) {
      return error && error.response ? error.response : error
    }
  },
  cleanRequestMaterials({ commit }) {
    commit('SET_REQUEST_MATERIALS', [])
  },
  cleanRequestsConsumptionMaterials({ commit }) {
    commit('SET_REQUESTS_CONSUMPTION_MATERIALS', [])
  }
}
