import ApiMsProjectsService from '@/services/ms_projects_service'
import { flattenFolders, setDocumentsTypesFromFolders, setFileFolderToChildrenFolder } from '../../helpers/document'
export const state = {
  documents: [],
  documentsTypes: [],
  foldersFromProjectOrPresale: [],
  loading: false,
  loadingChunked: false,
  percentageUploadedChunked: null,
  foldersDocuments: {
    items: [],
    total_pages: 0,
    total_items: 0,
  },
  namesDocuments: {
    items: [],
    total_pages: 0, 
    total_items: 0,
  },
  foldersDocumentsFromBusinessUnit: {
    items: [],
    total_pages: 0,
    total_items: 0,
  },
  foldersDocumentsTypes: {
    items: [],
    total_pages: 0,
    total_items: 0,
  },
  businessUnits: [],
  assignedAreas: [],
}

export const getters = {
  documents: (state) => state.documents,
  documentsTypes: (state) => state.documentsTypes,
  foldersFromProjectOrPresale: (state) => state.foldersFromProjectOrPresale,
  loading: (state) => state.loading,
  loadingChunked: (state) => state.loadingChunked,
  percentageUploadedChunked: (state) => state.percentageUploadedChunked,
  foldersDocuments: (state) => state.foldersDocuments,
  namesDocuments: (state) => state.namesDocuments,
  foldersDocumentsFromBusinessUnit: (state) => state.foldersDocumentsFromBusinessUnit,
  foldersDocumentsTypes: (state) => state.foldersDocumentsTypes,
  businessUnits: (state) => state.businessUnits,
  assignedAreas: (state) => state.assignedAreas,
}

export const mutations = {
  SET_DOCUMENTS(state, payload) {
    const flatFolders = setFileFolderToChildrenFolder(payload.data, payload.filters);
    state.documents = flatFolders
  },
  SET_DOCUMENTS_TYPES(state, payload) {
    const documentsTypes = setDocumentsTypesFromFolders(payload);
    state.documentsTypes = documentsTypes
  },
  SET_FOLDERS_FROM_PROJECT_OR_PRESALE(state, payload) {
    const flatFolders = flattenFolders(payload);
    state.foldersFromProjectOrPresale = flatFolders;
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_LOADING_CHUNKED(state, payload){
    state.loadingChunked = payload
  },
  SET_PERCENTAGE_UPLOADED_CHUNKED(state, payload){
    state.percentageUploadedChunked = payload
  },
  SET_FOLDERS_DOCUMENTS(state, payload){
    state.foldersDocuments = {
      items: payload.folders,
      total_pages: payload.number_of_pages,
      total_items: payload.total
    } 
  },
  SET_FOLDERS_DOCUMENTS_FROM_BUSINESS_UNIT(state, payload){
    state.foldersDocumentsFromBusinessUnit = {
      items: payload.folders,
      total_pages: payload.number_of_pages,
      total_items: payload.total
    } 
  },
  SET_FOLDERS_DOCUMENTS_TYPES(state, payload){
    state.foldersDocumentsTypes = {
      items: payload.folder_document_types,
      total_pages: payload.number_of_pages,
      total_items: payload.total
    } 
  },
  SET_NAMES_DOCUMENTS(state, payload){
    state.namesDocuments = {
      items: payload.document_types,
      total_pages: payload.number_of_pages,
      total_items: payload.total
    } 
  },
  SET_BUSINESS_UNITS(state, payload){
    state.businessUnits = payload
  },
  SET_ASSIGNED_AREAS(state, payload){
    state.assignedAreas = payload
  },
}

export const actions = {
  async getDocuments({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(
        `/documents/documents_folder_view`,
        payload
      )).data
      commit('SET_DOCUMENTS', {data: resp, filters: payload})
      if(payload.document_type_filter || payload.since || payload.until){
      }else {
        commit('SET_FOLDERS_FROM_PROJECT_OR_PRESALE', resp)
        commit('SET_DOCUMENTS_TYPES', resp)
      }
      return resp
    } catch (error) {
      console.log(error)
      return error
    }
  },
  async uploadDocumentFromPresaleOrProject({ commit }, payload) {
    let formData = new FormData()
    formData.append('body', JSON.stringify({
      ...payload.body
    }))
    if(payload.files){
      for(let i = 0; i < payload.files.length; i++){
        formData.append(`files`, payload.files[i])
      }
    }
    try {
      const resp = await ApiMsProjectsService.post(`/documents/new_document`, formData)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async getBusinessUnits({ commit }, payload = {}) {    
    try {
      const resp = (await ApiMsProjectsService.get(
        `/documents/business_units`,
        payload
      )).data
      commit('SET_BUSINESS_UNITS', resp)
      return resp
    }   
    catch (error) {
      return error
    }
  },
  async deleteDocument({_}, payload){
    let resp = null
    try {
       resp = (await ApiMsProjectsService.delete(
          `/documents/delete_document?document_id=${payload.document_id}&user=${payload.user}`, payload))
      return resp
    } catch (error) {
      return error.response
    }
  },
  async getAssignedAreas({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(
        `/documents/assigned_areas`,
        payload
      )).data
      commit('SET_ASSIGNED_AREAS', resp)
      return resp
    }   
    catch (error) {
      return error
    }
  },
  async uploadDocumentFromPlace({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = await ApiMsProjectsService.post(`/upload_document`, payload)
      return resp
    } catch (error) {
      return error
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async uploadDocumentChunckedFromPlace({ commit }, payload) {
    commit('SET_LOADING_CHUNKED', true)
    let {file, ...rest} = payload
    let resp = null
    let first_upload = true
    let final_upload = false
    const chunkSize = 50 * 1024 * 1024;
    const filename = file.name;
    for (let start = 0; start < file.size; start += chunkSize) {
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      let formData = new FormData()
      formData.append("body", JSON.stringify(rest));
      formData.append("file", chunk, filename)
      if(start > 0){
        first_upload = false
      }
       if (end === file.size) {
        final_upload = true
      }
      try {
        resp = await ApiMsProjectsService.post(
          `/upload_file_chunked_v2?first_upload=${first_upload}&final_upload=${final_upload}`,
           formData
        )
      } catch (error) {
        console.log(error)
        return error
      } finally {
      }
      const percent = (end / file.size) * 100;
      commit('SET_PERCENTAGE_UPLOADED_CHUNKED', percent);
    }
    commit('SET_LOADING_CHUNKED', false)
    commit('SET_PERCENTAGE_UPLOADED_CHUNKED', null);
    return resp
  },
  async getAllfoldersDocuments({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(`/documents/business_unit_folders`, payload)).data
      if(!payload.not_committed){
        if(payload.filter_from_business_unit){
          commit('SET_FOLDERS_DOCUMENTS_FROM_BUSINESS_UNIT', resp)
        }else{
          commit('SET_FOLDERS_DOCUMENTS', resp)
        }
      }
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllfoldersDocumentsTypes({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(`/documents/folder_document_types`, payload)).data
      commit('SET_FOLDERS_DOCUMENTS_TYPES', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllNamesDocuments({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(`/documents/document_types`, payload)).data
      commit('SET_NAMES_DOCUMENTS', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async updateOrCreateNameDocument({_}, payload){
    let resp = null
    try {
       if(payload.id){
        resp = (await ApiMsProjectsService.put(
          `/documents/update_document_type`, payload))
        
       }else {
          resp = (await ApiMsProjectsService.post(
           `/documents/new_document_type`, payload))
       }
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateOrCreateFolderDocument({_}, payload){
    let resp = null
    try {
       if(payload.folder_id){
        resp = (await ApiMsProjectsService.put(
          `/documents/update_folder`, payload))
        
       }else {
          resp = (await ApiMsProjectsService.post(
           `/documents/new_folder`, payload))
       }
      return resp
    } catch (error) {
      return error.response
    }
  },
  async associateNameDocument({_}, payload){
    let resp = null
    try {
       resp = (await ApiMsProjectsService.post(
          `/documents/associate_folder_document_type`, payload))
      return resp
    } catch (error) {
      return error.response
    }
  },
  async updateAssociatedNameDocument({_}, payload){
    let resp = null
    try {
       resp = (await ApiMsProjectsService.put(
          `/documents/update_folder_document_type`, payload))
      return resp
    } catch (error) {
      return error.response
    }
  },
  async disassociateNameDocument({_}, payload){
    let resp = null
    try {
       resp = (await ApiMsProjectsService.delete(
          `/documents/disassociate_folder_document_type`, payload))
      return resp
    } catch (error) {
      return error.response
    }
  },
  async deleteNameDocument({_}, payload){
    let resp = null
    try {
       resp = (await ApiMsProjectsService.delete(
          `/documents/delete_document_type?type_id=${payload.type_id}`, payload))
      return resp
    } catch (error) {
      return error.response
    }
  },
  async deleteFolderDocument({_}, payload){
    let resp = null
    try {
       resp = (await ApiMsProjectsService.delete(
          `/documents/delete_folder?folder_id=${payload.folder_id}`, payload))
      return resp
    } catch (error) {
      return error.response
    }
  },


}
