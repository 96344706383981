import ApiService from "@/state/api.service";

export const state = {
    lugaresInstalacion: [],
    lugarInstalacion: [],
    resultadoCrearLugarInstalacion: {},
    errorLugarInstalacion: null,
};

export const getters = {
    lugaresInstalacion: (state) => state.lugaresInstalacion,
    lugarInstalacion: (state) => state.lugarInstalacion,
    resultadoCrearLugarInstalacion: (state) =>
        state.resultadoCrearLugarInstalacion,
    errorLugarInstalacion: (state) => state.errorLugarInstalacion,
};

export const mutations = {
    setLugaresInstalacion(state, newValue) {
        state.lugaresInstalacion = newValue;
    },
    setLugarInstalacion(state, newValue) {
        state.lugarInstalacion = newValue;
    },
    setResultadoCrearLugarInstalacion(state, newValue) {
        state.resultadoCrearLugarInstalacion = newValue;
    },
    setError(state, newValue) {
        state.errorLugarInstalacion = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerLugaresInstalacion({ commit }, codigoProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/lugarInstalacion/proyecto/${codigoProyecto}`
            )
                .then((response) => {
                    commit("setLugaresInstalacion", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerLugaresInstalacionContacto({ commit }, lugarInstalacion) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/lugarInstalacion/proyecto/${lugarInstalacion.codigoProyecto}/${lugarInstalacion.emailContacto}`
            )
                .then((response) => {
                    commit("setLugaresInstalacion", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerLugaresInstalacionExcel({ commit }, codigoProyecto) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/lugarInstalacion/excel/${codigoProyecto}`)
                .then((response) => {
                    // commit("setLugaresInstalacion", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerLugaresInstalacionExcelContacto({ commit }, lugarInstalacion) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/lugarInstalacion/excel/${lugarInstalacion.codigoProyecto}/${lugarInstalacion.emailContacto}`
            )
                .then((response) => {
                    // commit("setLugaresInstalacion", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerLugarInstalacion({ commit }, lugarInstalacion) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/lugarInstalacion/${lugarInstalacion.codigoLugarInstalacion}/${lugarInstalacion.codigoProyecto}`
            )
                .then((response) => {
                    commit("setLugarInstalacion", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerLugarInstalacionId({ commit }, lugarInstalacionId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/lugarInstalacion/${lugarInstalacionId}`)
                .then((response) => {
                    commit("setLugarInstalacion", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearLugarInstalacion({ commit }, lugarInstalacion) {
        // commit('setResultadoCrearLugarInstalacion', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/lugarInstalacion`, lugarInstalacion.data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    replicarProyecto({ commit }, lugarInstalacion) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/lugarInstalacion/replicarProyecto`,
                lugarInstalacion
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearLugarInstalacionForm({ commit }, lugarInstalacion) {
        // commit('setResultadoCrearLugarInstalacion', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.postBlob(`${urlApi}/lugarInstalacion`, lugarInstalacion)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarLugarInstalacion({ commit }, lugarInstalacion) {
        // commit('setResultadoCrearLugarInstalacion', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/lugarInstalacion`, lugarInstalacion)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarLugarInstalacionActivo({ commit }, lugarInstalacion) {
        // commit('setResultadoCrearLugarInstalacion', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/lugarInstalacion/activo`,
                lugarInstalacion
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarLugarInstalacionImagen({ commit }, lugarInstalacion) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.putBlob(
                `${urlApi}/lugarInstalacion/actualizarImagen`,
                lugarInstalacion
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarLugarInstalacion({ commit }, lugarInstalacionId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/lugarInstalacion/${lugarInstalacionId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
