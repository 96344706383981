import ApiMsProjectsService from '@/services/ms_projects_service'

export const state = {
  loading: false
}

export const getters = {
  loading: (state) => state.loading
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  }
}

export const actions = {
  async getCrmDataFromProjectCode({ commit }, payload) {
    commit('SET_LOADING', true)
    try {
      const resp = (
        await ApiMsProjectsService.get(`/crm/crm_data/${payload.project_code}`, payload)
      ).data
      return resp
    } catch (error) {
      return null
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async updateCrmIdFromProjectCode({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.post(`/crm/link_crm_data?project_code=${payload.project_code}&crm_id=${payload.crm_id}`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async unbindCrmFromProjectCode({ commit }, payload) {
    try {
      const resp = await ApiMsProjectsService.delete(`/crm/unlink_crm_data?project_code=${payload.project_code}`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  }

}
