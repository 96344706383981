export const PLACES_RETURN_MATERIALS = [
    {
        id: 'Bodega Central',
        name: "Bodega Central"
    },
    {
        id: 'Proyecto',
        name: 'Seleccionar un proyecto'
    }
]

export const PLACE_RETURN_PROJECT_ID = 'Proyecto';

export const MATERIAL_REQUEST_RETURN_STATUS_LIST = {
    pending: 'Pendiente',
    approved: 'Aprobado',
    rejected: 'Rechazado'
}

export const REQUEST_MATERIAL = "SOLICITUD DE MATERIALES"
export const REQUEST_MATERIAL_URGENT = "SOLICITUD DE MATERIAL URGENTE"
export const REQUEST_SERVICE = "SOLICITUD DE SERVICIO"
export const REQUEST_SERVICE_URGENT = "SOLICITUD DE SERVICIO URGENTE"
export const REQUEST_SERVICE_MAVERICK = "SOLICITUD MAVERICK"
export const TYPE_REQUESTS = [
    {
        value: 1,
        name: REQUEST_MATERIAL
    },
    {
        value: 2,
        name: REQUEST_MATERIAL_URGENT
    }
]

export const TYPE_REQUESTS_SERVICE = [
    {
        value: 1,
        name: REQUEST_SERVICE
    },
    {
        value: 2,
        name: REQUEST_SERVICE_URGENT
    },
    {
        value: 3,
        name: REQUEST_SERVICE_MAVERICK
    }
]

export const MAP_REQUEST_SERVICE_FROM_MATERIAL = {
    [REQUEST_SERVICE]: REQUEST_MATERIAL,
    [REQUEST_SERVICE_URGENT]: REQUEST_MATERIAL_URGENT,
    [REQUEST_SERVICE_MAVERICK]: REQUEST_SERVICE_MAVERICK
}

export const TYPE_REQUEST_TRANSFER = "DESPACHO"
export const TYPE_REQUEST_RETREAT = "RETIRO"

export const TYPE_REQUESTS_DISPATCH = [
    {
        value: TYPE_REQUEST_TRANSFER,
        name: 'Traslado'
    },
    {
        value: TYPE_REQUEST_RETREAT,
        name: 'Retiro'
    }
]

export const STATUS_REQUESTS_MATERIALS = {
    pending: false,
    accepted: true,
}

export const CODE_WAREHOUSE_CENTRAL_FLUXSOLAR = 'BF0001'

export const CODE_SAP_STAGE_EXECUTION = 'RE4'
export const ACTIONS_TO_RECEPTION_MATERIAL = {
    received: "received",
    consumed: "consumed",
    returned: "returned",
}

export const TYPE_REQUESTS_MATERIALS = {
    standard: 1,
    mobile_warehouses: 2,
    transfer_materials: 3
}

export const TYPE_REQUEST_MATERIAL_ID = 1
export const TYPE_REQUEST_SERVICE_ID = 2
export const TYPE_REQUEST_MATERIAL_OR_SERVICE = [
    {
        value: TYPE_REQUEST_MATERIAL_ID,
        name: 'Solicitudes de Materiales'
    },
    {
        value: TYPE_REQUEST_SERVICE_ID,
        name: 'Solicitudes de Servicios'
    }
]

export const TYPE_DESTINY_PROJECT_ID = 1
export const TYPE_DESTINY_CECO_ID = 2
export const TYPES_DESTINY_REQUEST_MATERIALS = [
    {
        id: TYPE_DESTINY_CECO_ID,
        name: 'CECO'
    },
    {
        id: TYPE_DESTINY_PROJECT_ID,
        name: 'Proyecto'
    },
]

export const TYPES_REQUESTS_SAP = {
    InventoryTransferRequest: {name: "Solicitud de Traslado", eta: false},
    PurchaseRequest: {name: "Solicitud de Compra", eta: true},
}
