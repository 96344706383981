import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
const timeout = 800000
/**
 * Service to call HTTP request via Axios
 */
const ApiErpProjectsService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_MS_ERP_URL
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  async get(resource, params = {}, responseType = undefined) {
    this.init()
    return await Vue.axios.get(`${resource}`, {
      params,
      timeout: timeout,
      responseType: responseType,
      headers: {
        access_token: `${process.env.VUE_APP_MS_ERP_API_KEY}`
      }
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  async post(resource, params) {
    this.init()
    return await Vue.axios.post(`${resource}`, params, {
      timeout: timeout,
      headers: {
        access_token: `${process.env.VUE_APP_MS_ERP_API_KEY}`
      }
    })
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  async put(resource, params) {
    this.init()
    return Vue.axios.put(`${resource}`, params, {
      timeout: timeout,
      headers: {
        access_token: `${process.env.VUE_APP_MS_ERP_API_KEY}`
      }
    })
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  async delete(resource, payload = {}) {
    this.init()
    return await Vue.axios.delete(resource, {
      data: payload,
      timeout: timeout,
      headers: {
        access_token: `${process.env.VUE_APP_MS_ERP_API_KEY}`
      }
    })
  }
}

export default ApiErpProjectsService
