import ApiService from "@/state/api.service";

export const state = {
    bodegas: [],
    bodega: [],
    resultadoCrearBodega: {},
    errorBodega: null,
};

export const getters = {
    bodegas: (state) => state.bodegas,
    bodega: (state) => state.bodega,
    resultadoCrearBodega: (state) => state.resultadoCrearBodega,
    errorBodega: (state) => state.errorBodega,
};

export const mutations = {
    setBodegas(state, newValue) {
        state.bodegas = newValue;
    },
    setBodega(state, newValue) {
        state.bodega = newValue;
    },
    setResultadoCrearBodega(state, newValue) {
        state.resultadoCrearBodega = newValue;
    },
    setError(state, newValue) {
        state.errorBodega = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerBodegas({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/bodega/movil`)
                .then((response) => {
                    commit("setBodegas", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerBodega({ commit }, email_usuario) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/bodega/email/${email_usuario}`)
                .then((response) => {
                    commit("setBodega", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
