import ApiService from "@/state/api.service";

export const state = {
    informacionTenicas: [],
    informacionTenica: [],
    resultadoCrearInformacionTenica: {},
    errorInformacionTenica: null,
};

export const getters = {
    informacionTenicas: (state) => state.informacionTenicas,
    informacionTenica: (state) => state.informacionTenica,
    resultadoCrearInformacionTenica: (state) =>
        state.resultadoCrearInformacionTenica,
    errorInformacionTenica: (state) => state.errorInformacionTenica,
};

export const mutations = {
    setInformacionTenicas(state, newValue) {
        state.informacionTenicas = newValue;
    },
    setInformacionTenica(state, newValue) {
        state.informacionTenica = newValue;
    },
    setResultadoCrearInformacionTenica(state, newValue) {
        state.resultadoCrearInformacionTenica = newValue;
    },
    setError(state, newValue) {
        state.errorInformacionTenica = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerInformacionTecnicaId({ commit }, informacionTecnicaId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/informacionTecnica/${informacionTecnicaId}`
            )
                .then((response) => {
                    commit("setInformacionTenica", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerInformacionTecnica({ commit }, informacionTecnica) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/informacionTecnica/${informacionTecnica.codigoLugarInstalacion}/${informacionTecnica.codigoProyecto}`
            )
                .then((response) => {
                    commit("setInformacionTenica", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearInformacionTecnica({ commit }, informacionTenica) {
        // commit('setResultadoCrearInformacionTenica', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/informacionTecnica`, informacionTenica)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },

    actualizarInformacionTecnica({ commit }, informacionTenica) {
        // commit('setResultadoCrearInformacionTecnica', res.data)
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/informacionTecnica`, informacionTenica)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarInformacionTecnica({ commit }, informacionTecnicaId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/informacionTecnica/${informacionTecnicaId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
