import ApiMsMaterialsService from '@/services/ms_materials_service'

export const state = {
  loading: false,
  templates: []
}

export const getters = {
  loading: (state) => state.loading,
  templates: (state) => state.templates
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_TEMPLATES(state, payload) {
    state.templates = payload
  }
}

export const actions = {
  async getTemplates({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.get(`/get_templates?user=${payload.user}`)
      commit('SET_TEMPLATES', resp.data)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async createTemplate({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post(`/post_template`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async cloneDocumentToTemplate({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post(`/clone_document_to_template`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  },
  async cloneTemplateToDocument({ commit }, payload) {
    try {
      const resp = await ApiMsMaterialsService.post(`/clone_template_to_document`, payload)
      return resp
    } catch (error) {
      return error.response
    }
  }
}
