import ApiMsProjectsService from '@/services/ms_projects_service'

export const state = {
  presales: {
    items: [],
    total_items: 0,
    total_pages: 1,
  },
}

export const getters = {
  presales: (state) => state.presales,
}

export const mutations = {
  SET_PRESALES(state, payload) {
    state.presales = {
      items: payload.preventas,
      total_items: payload.total,
      total_pages: payload.number_of_pages,
    }
  }
}

export const actions = {
  async getAllPresales({ commit }, payload = {}) {
    try {
      const resp = (await ApiMsProjectsService.get(`/preventas/preventas`, payload)).data
      commit('SET_PRESALES', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async updateOrCreatePresale({_}, payload){
    let resp = null
    try {
       if(payload.id){
        resp = (await ApiMsProjectsService.put(
          `/preventas/preventas`, payload))
        
       }else {
          resp = (await ApiMsProjectsService.post(
           `/preventas/preventas`, payload))
       }
      return resp
    } catch (error) {
      return error.response
    }
  },
  async deletePresale({_}, payload){
    let resp = null
    let queryParamsFormatted = new URLSearchParams(payload).toString()
    try {
       resp = (await ApiMsProjectsService.delete(
          `/preventas/preventas/${payload.preventa_id}?${queryParamsFormatted}`, payload))
      return resp
    } catch (error) {
      return error.response
    }
  },
}
