import ApiService from "@/state/api.service";

export const state = {
    tipoClientes: [],
    tipoCliente: [],
    resultadoCrearTipoCliente: {},
    errorTipoCliente: null,
};

export const getters = {
    tipoClientes: (state) => state.tipoClientes,
    tipoCliente: (state) => state.tipoCliente,
    resultadoCrearTipoCliente: (state) => state.resultadoCrearTipoCliente,
    errorTipoCliente: (state) => state.errorTipoCliente,
};

export const mutations = {
    setTipoClientes(state, newValue) {
        state.tipoClientes = newValue;
    },
    setTipoCliente(state, newValue) {
        state.tipoCliente = newValue;
    },
    setResultadoCrearTipoCliente(state, newValue) {
        state.resultadoCrearTipoCliente = newValue;
    },
    setError(state, newValue) {
        state.errorTipoCliente = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerTipoClientes({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/tipoCliente`)
                .then((response) => {
                    commit("setTipoClientes", response.data.body);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearTipoCliente({ commit }, tipoCliente) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/tipoCliente`, tipoCliente)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarTipoCliente({ commit }, tipoCliente) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/tipoCliente`, tipoCliente)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarTipoCliente({ commit }, tipoClienteId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${urlApi}/tipoCliente/${tipoClienteId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
