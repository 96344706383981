import ApiService from "@/state/api.service";

export const state = {
    solicitudMateriales: [],
    solicitudMaterial: [],
    resultadoCrearSolicitudMaterial: {},
    errorSolicitudMateria: null,
};

export const getters = {
    solicitudMateriales: (state) => state.solicitudMateriales,
    solicitudMaterial: (state) => state.solicitudMaterial,
    resultadoCrearSolicitudMaterial: (state) =>
        state.resultadoCrearSolicitudMaterial,
    errorSolicitudMateria: (state) => state.errorSolicitudMateria,
};

export const mutations = {
    setSolicitudMateriales(state, newValue) {
        state.solicitudMateriales = newValue;
    },
    setSolicitudMaterial(state, newValue) {
        state.solicitudMaterial = newValue;
    },
    setResultadoCrearSolicitudMaterial(state, newValue) {
        state.resultadoCrearSolicitudMaterial = newValue;
    },
    setError(state, newValue) {
        state.errorSolicitudMateria = newValue;
    },
};
const urlApi = process.env.VUE_APP_APIURL;
export const actions = {
    obtenerSolicitudMateriales({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/solicitudMaterial`)
                .then((response) => {
                    commit("setSolicitudMateriales", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialesProyectoLugarInstalacion(
        { commit },
        solicitudMaterial
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/solicitudMaterial/${solicitudMaterial["codigoLugarInstalacion"]}/${solicitudMaterial["codigoProyecto"]}`
            )
                .then((response) => {
                    commit("setSolicitudMateriales", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialesBodega({ commit }, codigoBodega) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/solicitudMaterial/bodega/${codigoBodega}`)
                .then((response) => {
                    commit("setSolicitudMaterial", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialesBodegaMovil({ commit }, codigoBodega) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/solicitudMaterial/bodegamovil/solicitudes/${codigoBodega}`
            )
                .then((response) => {
                    // commit("setSolicitudMaterial", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialesBodegaUsuarioBodega({ commit }, usuario) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/solicitudMaterial/bodegamovil/solicitudes/usuariobodega/${usuario.token_usuario}/${usuario.codigo_bodega}`
            )
                .then((response) => {
                    // commit("setSolicitudMaterial", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {obtenerSolicitudMaterialesBodegaUsuarioBodega
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialesBodegaUsuarioMovil({ commit }, tokenUsuario) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/solicitudMaterial/bodegamovil/solicitudes/usuario/${tokenUsuario}`
            )
                .then((response) => {
                    // commit("setSolicitudMaterial", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialesBodega({ commit }, codigoBodega) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${urlApi}/solicitudMaterial/bodega/${codigoBodega}`)
                .then((response) => {
                    commit("setSolicitudMaterial", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterial({ commit }, solicitud_material_id) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/solicitudMaterial/id/${solicitud_material_id}`
            )
                .then((response) => {
                    commit("setSolicitudMaterial", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    obtenerSolicitudMaterialDetalles({ commit }, solicitud_material_id) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(
                `${urlApi}/solicitudMaterial/detalle/${solicitud_material_id}`
            )
                .then((response) => {
                    commit("setSolicitudMaterial", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    filtro({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/solicitudMaterial/filtro`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    excelGeneral({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/solicitudMaterial/excelGeneral`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    excel({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/solicitudMaterial/excel`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    productosSolicitadosNuevamente({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(
                `${urlApi}/solicitudMaterial/productosSolicitadosNuevamente`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    crearSolicitudMaterial({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post(`${urlApi}/solicitudMaterial`, solicitudMaterial)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterial({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(`${urlApi}/solicitudMaterial`, solicitudMaterial)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialRechazada({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/solicitudMaterial/rechazada`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialRecepcionada({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/solicitudMaterial/recepcionada`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialValeConsumo({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/solicitudMaterial/valeconsumo`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialDevolucion({ commit }, solicitudMaterial) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/solicitudMaterial/devolucion`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialProductoRecepcionado(
        { commit },
        solicitud_material_detalle_id
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/solicitudMaterial/detalle/recepcionado`,
                solicitud_material_detalle_id
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialProductoCantidadRecepcionada(
        { commit },
        solicitudMaterial
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/solicitudMaterial/detalle/cantidadRecepcionada`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialProductoCantidadConsumida(
        { commit },
        solicitudMaterial
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/solicitudMaterial/detalle/cantidadConsumida`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    actualizarSolicitudMaterialProductoCantidadDevuelta(
        { commit },
        solicitudMaterial
    ) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.put(
                `${urlApi}/solicitudMaterial/detalle/cantidadDevuelta`,
                solicitudMaterial
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
    eliminarSolicitudMaterial({ commit }, solicitudMaterialId) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(
                `${urlApi}/solicitudMaterial/id/${solicitudMaterialId}`
            )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error == "ECONNABORTED") {
                        commit("setError", "limpiarTimeout");
                        setTimeout(()=>{
                            commit("setError", "timeout");
                        },800)
                    }
                    if (error == "Network Error") {
                        commit("setError", "limpiarSininternet");
                        setTimeout(()=>{
                            commit("setError", "sininternet");
                        },800)
                    }
                    reject(error);
                });
        });
    },
};
