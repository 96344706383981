<script>
import appConfig from "@/app.config";

export default {
  page: {
    title: "Pagina no encontrada",
    meta: [{ name: "description", content: appConfig.description }],
  },
};
</script>
<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-center align-items-center">
            <img
              width="200px"
              v-bind:src="require('@/assets/images/logo_fluxsolar.png')"
              alt="logo"
            />
          </div>
          <div class="text-center mb-2">
            <h4 class="text-uppercase mt-5">Disculpa, esta página no existe</h4>
            <div class="mt-5 text-center">
              <router-link class="btn btn-warning" to="/"
                >Volver al inicio</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <img src="@/assets/images/error-img.png" alt class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
