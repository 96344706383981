import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/helpers/authservice/jwt.service'
const timeout = 400000
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = 'https://localhost:44353'
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`
  },

  query(resource, params) {
    return Vue.axios.get(resource, params, { timeout: timeout }).catch((error) => {
      // console.log(error);
      throw new Error(`ApiService ${error}`)
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return Vue.axios.get(`${resource}`, { timeout: timeout }).catch((error) => {
      console.log(error.message)
      if (error.code != null) {
        if (error.code == 'ECONNABORTED') {
          throw error.code.toString()
        }
      }

      if (error.message != null) {
        if (error.message == 'Network Error') {
          throw error.message.toString()
        }
      }
      throw new Error(`ApiService ${error}`)
    })
  },

  getBlob(resource, slug = '') {
    const config = {
      responseType: 'blob'
    }
    return Vue.axios.get(`${resource}`, { timeout: timeout }, config).catch((error) => {
      if (error.code != null) {
        if (error.code == 'ECONNABORTED') {
          throw error.code.toString()
        }
      }

      if (error.message != null) {
        if (error.message == 'Network Error') {
          throw error.message.toString()
        }
      }
      throw error // new Error(`ApiService ${error}`);
    })
  },
  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params, { timeout: timeout }).catch((error) => {
      console.log('post', error.code)
      if (error.code != null) {
        if (error.code == 'ECONNABORTED') {
          throw error.code.toString()
        }
      }

      if (error.message != null) {
        if (error.message == 'Network Error') {
          throw error.message.toString()
        }
      }
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postBlob(resource, params) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return Vue.axios
      .post(`${resource}`, params, config, { timeout: timeout })
      .catch((error) => {
        if (error.code != null) {
          if (error.code == 'ECONNABORTED') {
            throw error.code.toString()
          }
        }

        if (error.message != null) {
          if (error.message == 'Network Error') {
            throw error.message.toString()
          }
        }
      })
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}`, params, { timeout: timeout }).catch((error) => {
      if (error.code != null) {
        if (error.code == 'ECONNABORTED') {
          throw error.code.toString()
        }
      }

      if (error.message != null) {
        if (error.message == 'Network Error') {
          throw error.message.toString()
        }
      }
    })
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params, { timeout: timeout }).catch((error) => {
      if (error.code != null) {
        if (error.code == 'ECONNABORTED') {
          throw error.code.toString()
        }
      }

      if (error.message != null) {
        if (error.message == 'Network Error') {
          throw error.message.toString()
        }
      }
    })
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  putBlob(resource, params) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return Vue.axios
      .put(`${resource}`, params, config, { timeout: timeout })
      .catch((error) => {
        if (error.code != null) {
          if (error.code == 'ECONNABORTED') {
            throw error.code.toString()
          }
        }

        if (error.message != null) {
          if (error.message == 'Network Error') {
            throw error.message.toString()
          }
        }
      })
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource, { timeout: timeout }).catch((error) => {
      if (error.code != null) {
        if (error.code == 'ECONNABORTED') {
          throw error.code.toString()
        }
      }

      if (error.message != null) {
        if (error.message == 'Network Error') {
          throw error.message.toString()
        }
      }
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}

export default ApiService
